import type { RouteRecordName } from 'vue-router'

export interface MenuItem {
  label: string
  path?: string
  key: string
  show?: boolean
  children?: MenuItem[]
  disabled?: boolean
}

export function isMenuActive(item: MenuItem, name: RouteRecordName | undefined | null, parent?: MenuItem) {
  if (item.key === 'index')
    return name === 'index' || name === 'tenant'

  if (parent?.key === 'devtools')
    return name?.toString().includes(`${parent.key}-${item.key}`) && !item.children?.length

  return name?.toString().includes(item.key) && !item.children?.length
}

// TODO: Remove route keys that has tenant in after removing tenant from routing
export function menuOptions(): MenuItem[] {
  const { t } = useI18n()
  return [
    {
      key: 'index',
      label: t('common.home'),
      path: '/',
    },
    {
      key: 'tenant-stats',
      label: t('common.analytics'),
      path: '/stats',
      show: !isProduction,
    },
    {
      children: [
        {
          key: 'tenant-events',
          label: t('common.eventsAndPrograms'),
          path: '/events',
          show: !Tenant.isGlobal(),
        },
        {
          key: 'tenant-users',
          label: t('common.players'),
          path: '/users',
        },
        {
          key: 'tenant-clubs',
          label: t('common.clubs', 10),
          path: '/clubs',
        },
      ],
      key: 'competition',
      label: t('common.competition'),
    },
    {
      children: [
        {
          key: 'theme',
          label: t('common.theming'),
          path: '/settings/theme',
          show: !Tenant.isGlobal(),
        },
        {
          key: 'localization',
          label: t('common.languagesAndLocalization'),
          path: '/settings/localization',
        },
        {
          key: 'notifications',
          label: t('common.notifications'),
          path: '/settings/notifications',
        },
        {
          key: 'payments',
          label: t('common.payments'),
          path: '/payments',
          show: !isProduction,
        },
      ],
      key: 'settings',
      label: t('common.settings'),
    },
    {
      children: [
        {
          key: 'webhooks',
          label: t('common.webhook', 2),
          path: '/devtools/webhooks',
        },
        {
          key: 'keys',
          label: t('common.key', 2),
          path: '/devtools/keys',
        },
        {
          key: 'jobs',
          label: t('common.jobs'),
          path: '/devtools/jobs',
        },
        {
          key: 'logs',
          label: t('common.log', 2),
          path: '/devtools/logs',
        },
      ],
      key: 'devtools',
      label: t('common.devTools'),
    },
    {
      children: [
        {
          key: 'organization',
          label: t('common.organization'),
          path: '/admin/organization/roles',
        },
        {
          disabled: true,
          key: 'billing',
          label: t('common.billing'),
          path: '/admin/billing',
          show: !isProduction,
        },
      ],
      key: 'admin',
      label: t('common.admin'),
    },
  ]
}
