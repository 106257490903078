import { useDialog } from './dialog'
import { EBModalPosition } from '~/components/base/b-modal.types'
import type BDialog from '~/components/base/b-dialog.vue'

export enum MessageType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export function useMessage(options: {
  /**
   * Type of message container to be displayed
   */
  type?: MessageType
  /**
   * String message to be displayed
   */
  message: string
  /**
   * Auto-dismiss timeout in ms
   * If not set 3 seconds is default
   */
  timeout?: boolean | number
  /**
   * Callback function to be called when message is dismissed
   */
  onDismiss?: () => void
}) {
  const lastElement = Message.stack[Message.stack.length - 1]
  const el = useDialog({
    message: options.message,
    noOverlay: true,
    onDismiss: options.onDismiss,
    persistent: true,
    position: EBModalPosition.TOP,
    positionStyle: `margin-top: ${lastElement?.bottom ?? 16}px`,
    seamless: true,
    timeout: options.timeout === false
      ? false
      : typeof options.timeout === 'number'
        ? options.timeout
        : true,
    type: options.type,
  }) as InstanceType<typeof BDialog>
  Message.stack.push(el)
}

export class Message {
  static stack: InstanceType<typeof BDialog>[] = []

  static async onDismiss() {
    Message.stack.splice(0, 1)
    // We use async for loop to wait for each dialog to
    // update its position with nextTick inside the
    // update position function
    for await (const dialog of Message.stack)
      await dialog.updatePosition(Message.stack.indexOf(dialog))
  }

  static success(message: string, timeout?: boolean | number) {
    useMessage({
      message,
      onDismiss: this.onDismiss,
      timeout,
      type: MessageType.SUCCESS,
    })
  }

  static warning(message: string, timeout?: boolean | number) {
    useMessage({
      message,
      onDismiss: this.onDismiss,
      timeout,
      type: MessageType.WARNING,
    })
  }

  static error(message: string, timeout?: boolean | number) {
    useMessage({
      message,
      onDismiss: this.onDismiss,
      timeout,
      type: MessageType.ERROR,
    })
  }

  static info(message: string, timeout?: boolean | number) {
    useMessage({
      message,
      onDismiss: this.onDismiss,
      timeout,
      type: MessageType.INFO,
    })
  }
}
