<script setup lang="ts">
const props = defineProps({
  bordered: Boolean,
  height: String,
  size: String,
  square: Boolean,
  tag: {
    default: 'div',
    type: String,
  },
  type: {
    default: 'rect',
    type: String,
    validator: (v: string) => skeletonTypes.includes(v),
  },
  width: String,
})

const style = computed(() => {
  const size = props.size ? [props.size, props.size] : [props.width, props.height]
  return {
    height: size[1],
    width: size[0],
  }
})

const classes = computed(() =>
  `b-skeleton b-skeleton--type-${props.type} b-skeleton--anim-wave`
  + `${props.square ? ' b-skeleton--square' : ''}`
  + `${props.bordered ? ' b-skeleton--bordered' : ''}`,
)
</script>

<template>
  <component :is="tag" data-test="wrapper" :class="classes" :style="style">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
.b-skeleton {
  @apply box-border b-rd-2 bg-dark bg-opacity-12;

  &:before {
    content: '\00a0';
  }

  &--type {
    &-text {
      @apply w-full h-4;
    }

    &-circle {
      @apply h-12 w-12 b-rd-50%;
    }

    &-btn {
      @apply h-9 w-22 b-rd-4px;
    }
    &-input {
      @apply h-9;
    }
  }

  &--bordered {
    @apply b-1 b-black b-opacity-5;
  }

  &--square {
    @apply b-rd-0;
  }

  &--anim-wave {
    @apply relative overflow-hidden z-1 cursor-wait;

    &:after {
      @apply absolute top-0 right-0 bottom-0 left-0 z-0;
      content: '';
      background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255, 255, 255, 0.7), rgba(255,255,255,0));
      animation: b-skeleton-wave 1500ms linear .3s infinite;
    }
  }
}

@keyframes b-skeleton-wave {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
