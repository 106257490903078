<script setup lang="ts">
import type { ClassType } from '~/utils'

const props = withDefaults(defineProps<{
  sizeXs?: boolean
  sizeSm?: boolean
  brand?: boolean
  primary?: boolean
  secondary?: boolean
  warning?: boolean
  error?: boolean
  ghost?: boolean
  full?: boolean
  contentClass?: ClassType
  circle?: boolean
  disabled?: boolean
  type?: 'button' | 'reset' | 'submit'
}>(), {
  type: 'button',
})

const computedClass = computed(() => ({
  'b-btn': true,
  'b-btn__brand': props.brand,
  'b-btn__error': props.error,
  'b-btn__full': props.full,
  'b-btn__ghost': props.ghost,
  'b-btn__primary': props.primary,
  'b-btn__secondary': props.secondary,
  'b-btn__sm': props.sizeSm,
  'b-btn__warning': props.warning,
  'b-btn__xs': props.sizeXs,
}))
</script>

<template>
  <button :class="computedClass" :disabled="disabled" :type="type">
    <div class="focus-helper" />
    <div class="b-btn__content" :class="contentClass">
      <slot />
    </div>
  </button>
</template>

<style lang="scss">
.b-btn {
  @apply text-background-text;
  @apply b-rd-1 flex box-border;
  @apply relative p-0;

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  &__full {
    @apply important-w-full;
  }

  &__brand {
    @apply bg-brand text-brand-text;

    &:not(:disabled):hover {
      @apply bg-brand text-brand-text;
      .focus-helper {
        @apply bg-white opacity-25;
      }
    }

    &:not(:disabled):focus {
      @apply bg-brand text-brand-text;
    }
  }

  &__ghost {
    @apply bg-ghost text-ghost-text;

    &:not(:disabled):hover {
      @apply bg-ghost text-ghost-text;
      .focus-helper {
        @apply bg-black opacity-5;
      }
    }

    &:not(:disabled):focus {
      @apply bg-ghost text-ghost-text;
    }
  }

  &__primary {
    @apply bg-primary text-primary-text;

    &:not(:disabled):hover {
      @apply bg-primary text-primary-text;
      .focus-helper {
        @apply bg-white opacity-15;
      }
    }

    &:not(:disabled):focus {
      @apply bg-primary text-primary-text;
    }
  }

  &__error {
    @apply bg-error text-primary-text;

    &:not(:disabled):hover {
      @apply bg-error text-primary-text;
      .focus-helper {
        @apply bg-white opacity-15;
      }
    }

    &:not(:disabled):focus {
      @apply bg-error text-primary-text;
    }
  }

  &__warning {
    @apply bg-warning text-warning-text;

    &:not(:disabled):hover {
      @apply bg-warning text-warning-text;
      .focus-helper {
        @apply bg-white opacity-15;
      }
    }

    &:not(:disabled):focus {
      @apply bg-warning text-warning-text;
    }
  }

  &__secondary {
    @apply bg-secondary text-secondary-text;
    @apply b-2 b-secondary-border b-rd-1;

    &:not(:disabled):hover {
      @apply bg-secondary text-secondary-text;
      .focus-helper {
        @apply bg-black opacity-15;
      }
    }

    &:not(:disabled):focus {
      @apply bg-secondary text-secondary-text;
    }
  }

  .b-btn__content {
    @apply font-600 px-4 h-full h-8;
    @apply flex-1 flex items-center justify-center
  }

  &__xs {
    @apply text-3;

    .b-btn__content {
      @apply px-2 h-6;
    }
  }

  &__sm {
    @apply text-3;

    .b-btn__content {
      @apply px-2 h-7;
    }
  }

  &:not(:disabled):active {
    .focus-helper {
      @apply bg-black opacity-30;
    }
  }

  .focus-helper {
    @apply absolute top-0 left-0 w-full h-full opacity-0 b-rd-1;
    transition: background-color .3s cubic-bezier(.25, .8, .5, 1), opacity .4s cubic-bezier(.25, .8, .5, 1);
  }
}
</style>
