<script setup lang="ts">
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import { useJobsWebsocket } from '~/composables'
import { useAppStore } from '~/store/index'
import { useUserStore } from '~/store/users'
import { useJobsStore } from '~/store/jobs'

const appStore = useAppStore()
const userStore = useUserStore()
const jobStore = useJobsStore()
const route = useRoute()
const router = useRouter()
const jobsWebsocket = useJobsWebsocket()

const initialLoading = computed(() => appStore.initialLoading)
const isDrawerShown = computed(() => appStore.isDrawerShown)
const isLoggedIn = computed(() => !!userStore.loggedUser)
const finishedJob = computed(() => jobStore.job)

function setDefaultTenant() {
  const tenant = userStore.getUserTenant()
  const defaultTenant = tenant ?? '_global_'
  appStore.setTenant(defaultTenant)
  return encodeURIComponent(defaultTenant)
}

watch(finishedJob, async () => {
  if (!finishedJob.value)
    return

  jobsWebsocket.handle(finishedJob.value)
})

onMounted(async () => {
  if (!isLoggedIn.value) {
    router.push(`/login?login_url=${route.fullPath}`)
    return
  }

  let routeTenant = route.params.tenant
  // Control last command to rewrite the _ in the route if necessary
  // We need this so we initiate the route properly and then just push
  // to the rewritten route with tenant proper initialized
  let rewriteUnderscore = false

  if (routeTenant) {
    if (routeTenant === '_') {
      routeTenant = setDefaultTenant()
      // If the _ is in the route we point to the right route
      // at the end of the loading
      rewriteUnderscore = true
    }

    appStore.setTenant(decodeURIComponent(routeTenant?.toString()))
  }
  else if (!routeTenant) {
    const defaultTenant = setDefaultTenant()
    if (routeTenant && defaultTenant)
      router.push(route.path.replace(routeTenant?.toString(), defaultTenant))
  }

  router.beforeEach(() => {
    if (window.innerWidth < 768)
      appStore.setDrawerShown(false)
  })

  appStore.setInitialLoading(false)
  if (rewriteUnderscore)
    await router.push(route.path.replace('/_/', `/${routeTenant}/`))
})
</script>

<template>
  <div class="flex flex-col">
    <navbar />
    <div class="h-[calc(100vh-calc(calc(68rem/16)*2+1px))] flex">
      <navmenu />
      <navdrawer />
      <overlay-scrollbars-component
        defer class="w-full" :options="{
          scrollbars: {
            autoHide: 'scroll',
          },
          overflow: {
            x: 'hidden',
          },
        }"
      >
        <main class="relative max-w-full w-full bg-background p-2 text-background-text xs:p-4">
          <div
            v-if="initialLoading"
            class="mt-16 flex flex-col items-center justify-center transition-all duration-200"
            :class="{ 'w-[calc(100% - 248px)]': isDrawerShown, 'w-full': !isDrawerShown }"
          >
            <b-spinner />
          </div>
          <router-view v-else />
        </main>
      </overlay-scrollbars-component>
    </div>
  </div>
</template>
