<script setup lang="ts">
import type { RendererElement } from 'vue'

const props = withDefaults(defineProps<{
  isActive?: boolean
  duration?: number
}>(), {
  duration: 300,
})

const emit = defineEmits(['show', 'hide'])

let animating = false
let doneFn: (() => any) | null
let element: RendererElement
let timer: string | number | NodeJS.Timeout | null | undefined = null
let timerFallback: string | number | NodeJS.Timeout | null | undefined = null
let animationListener: TimerHandler | null
let lastEvent: string

function cleanup() {
  doneFn && doneFn()
  doneFn = null
  animating = false

  if (timer !== null) {
    clearTimeout(timer)
    timer = null
  }

  if (timerFallback !== null) {
    clearTimeout(timerFallback)
    timerFallback = null
  }

  element && animationListener && element.removeEventListener('transitionend', animationListener)
  animationListener = null
}

function begin(el: RendererElement, height: number | null | undefined, done: (() => any) | null) {
  if (height !== undefined && height !== null)
    el.style.height = `${height}px`

  el.style.transition = `height ${props.duration}ms cubic-bezier(.25, .8, .50, 1)`

  animating = true
  doneFn = done
}

function end(el: RendererElement, event: 'show' | 'hide') {
  el.style.overflowY = ''
  el.style.height = ''
  el.style.transition = ''
  cleanup()
  event !== lastEvent && emit(event)
}

function onEnter(el: RendererElement, done: any): void {
  let pos: number | null = 0
  element = el

  if (animating === true) {
    cleanup()
    pos = el.offsetHeight === el.scrollHeight ? 0 : null
  }
  else {
    lastEvent = 'hide'
    el.style.overflowY = 'hidden'
  }

  begin(el, pos, done)

  timer = setTimeout(() => {
    timer = null
    el.style.height = `${el.scrollHeight}px`
    animationListener = (evt: { target: RendererElement }) => {
      timerFallback = null

      if (Object(evt) !== evt || evt.target === el)
        end(el, 'show')
    }
    el.addEventListener('transitionend', animationListener)
    timerFallback = setTimeout(animationListener, props.duration * 1.1)
  }, 100)
}

function onLeave(el: RendererElement, done: any) {
  let pos
  element = el

  if (animating) {
    cleanup()
  }
  else {
    lastEvent = 'show'
    el.style.overflowY = 'hidden'
    pos = el.scrollHeight
  }

  begin(el, pos, done)

  timer = setTimeout(() => {
    timer = null
    el.style.height = '0px'
    animationListener = (evt: { target: Element }) => {
      timerFallback = null

      if (Object(evt) !== evt || evt.target === el)
        end(el, 'hide')
    }
    el.addEventListener('transitionend', animationListener)
    timerFallback = setTimeout(animationListener, props.duration * 1.1)
  }, 100)
}

onBeforeUnmount(() => {
  animating && cleanup()
})
</script>

<template>
  <transition
    :css="false"
    :appear="isActive"
    @enter="onEnter"
    @leave="onLeave"
  >
    <slot />
  </transition>
</template>
