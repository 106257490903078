export function useDetectAutofill(elementId: string) {
  const isAutoFilled = ref(false)

  function detectAutofill(element: Element): Promise<boolean> {
    const maxChecks = 10
    let numChecks = 0
    let currentInterval: NodeJS.Timer

    return new Promise((resolve) => {
      currentInterval = setInterval(() => {
        numChecks++
        const checkAutoFilled = window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button'

        if (!checkAutoFilled && numChecks < maxChecks)
          return

        clearInterval(currentInterval)
        resolve(checkAutoFilled)
      }, 100)
    })
  }

  onMounted(async () => {
    const element = document.querySelector(`#${elementId}`)
    if (!element)
      return

    isAutoFilled.value = await detectAutofill(element)
  })

  return {
    isAutoFilled,
  }
}
