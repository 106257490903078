<script setup lang="ts">
import type { Event } from '@cevo/gfinity-api-sdk'
import { EventsEndpoint } from '@cevo/gfinity-api-sdk'
import { ArrowNarrowRight, ChevronRight, FileText } from '@vicons/tabler'
import dayjs from 'dayjs'

const { t } = useI18n()

const events = ref<Event[]>([])
const isLoadingEvents = ref(false)
const isLoadingOverview = ref(false)

const dateRange = computed(() => ({
  from: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
  to: dayjs().format('YYYY-MM-DD'),
}))

async function loadEvents() {
  try {
    isLoadingEvents.value = true

    const response = await EventsEndpoint.getEvents({
      'filter[status]': 'active',
      'page[number]': 1,
      'page[size]': 6,
      'sort': '-date.from',
    })
    events.value = response.data.data
  }
  catch (error) {
    console.error(error)
  }
  finally {
    isLoadingEvents.value = false
  }
}

async function loadOverview() {
  isLoadingOverview.value = true
  setTimeout(() => isLoadingOverview.value = false, 1000)
}

onMounted(async () => {
  loadEvents()
  loadOverview()
})
</script>

<template>
  <div>
    <div class="max-w-192">
      <h1 class="mb-0 mb-6 page-title">
        {{ t('common.welcome') }}
      </h1>

      <div class="mb-10">
        <h2 class="mb-0 mb-4 color-dark-40 page-heading">
          {{ t('common.overview') }}
        </h2>

        <div class="flex flex-col gap-2 de:flex-row">
          <div class="flex-1">
            <dashboard-analytics-teams-sparkline-card class="h-100% min-w-62 flex-1" :date="dateRange" current-number />
          </div>
          <div class="flex-1">
            <dashboard-analytics-players-sparkline-card class="h-100% min-w-62 flex-1" :date="dateRange" current-number />
          </div>
          <div class="flex-1">
            <dashboard-analytics-matches-sparkline-card class="h-100% min-w-62 flex-1" :date="dateRange" current-number />
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-224">
      <div class="mb-8">
        <h2 class="mb-0 mb-4 color-dark-40 page-heading">
          {{ t('common.events') }}
        </h2>

        <div v-if="isLoadingEvents" class="b-rd-1 bg-dark-10">
          <b-skeleton height="80px" width="100%" />
        </div>
        <div v-else-if="!events.length" class="b-rd-1 bg-dark-10">
          <div class="py-6 text-center">
            <h2 class="pb-2 text-big">
              {{ t('home.noEventsYet') }}
            </h2>
            <router-link
              class="flex items-center justify-center color-link"
              to="#"
            >
              {{ t('home.createFirstEvent') }}

              <b-icon size="16" class="ml-1">
                <arrow-narrow-right />
              </b-icon>
            </router-link>
          </div>
        </div>
        <div v-else class="flex">
          <div class="hd:grid-cols-4 grid grid-cols-1 w-full items-end gap-6 de:grid-cols-3 ta:grid-cols-2">
            <event-card v-for="event in events" :key="event.id" :event="event" cover />
          </div>
        </div>
      </div>

      <div class="mb-10">
        <h2 class="mb-0 mb-4 color-dark-40 page-heading">
          {{ t('common.docs') }}
        </h2>

        <div class="w-50 b-1 b-dark-10 b-rd-1 b-solid">
          <router-link class="w-full flex justify-between p-2" to="#">
            <div class="flex items-center text-small">
              <b-icon size="14" color="#9D9DA2" class="mr-1">
                <file-text />
              </b-icon>
              {{ t('home.athlosReadme') }}
            </div>
            <b-icon size="14" color="#0A0A16" class="mr-1">
              <chevron-right />
            </b-icon>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
