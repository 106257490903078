<script setup lang="ts">
import type { Placement } from 'floating-vue'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import type { BDropdownOption } from './b-dropdown.types'

const props = withDefaults(defineProps<{
  options?: BDropdownOption[]
  selected?: string | string[]
  minWidth?: string
  maxHeight?: string
  placement?: Placement
}>(), {
  maxHeight: '45vh',
  placement: 'bottom',
})

const emit = defineEmits<{
  (e: 'select', value: string): void
}>()

function onSelect(item: BDropdownOption, hide: () => void) {
  if (item.disabled)
    return

  if (item.props?.onClick) {
    item.props?.onClick?.()
    hide()
    return
  }

  emit('select', item.key)
  hide()
}

function checkSelected(key: string) {
  if (!props.selected || props.selected.length === 0)
    return false

  if (Array.isArray(props.selected))
    return props.selected.includes(key)

  return props.selected === key
}
</script>

<template>
  <v-dropdown :placement="placement">
    <slot />
    <template #popper="{ hide }">
      <div :style="maxHeight ? `max-height: ${maxHeight}` : ''">
        <overlay-scrollbars-component
          defer
          class="w-full"
          :style="maxHeight ? `max-height: ${maxHeight}` : ''"
          :options="{
            scrollbars: {
              autoHide: 'scroll',
            },
            overflow: {
              x: 'hidden',
            },
          }"
        >
          <div class="p-1" :style="minWidth ? `min-width: ${minWidth}` : ''">
            <slot v-if="$slots.content" name="content" :hide="hide" />
            <ul v-else-if="options?.length">
              <li
                v-for="row in options"
                :key="row.key"
                class="flex cursor-pointer items-center gap-2 b-rd-1 p-2 hover:bg-dark-5"
                :class="{
                  'opacity-50': row.disabled,
                  'important-bg-dark-10': checkSelected(row.key),
                }"
                :style="row.textColor ? `color: ${row.textColor}` : ''"
                @click="onSelect(row, hide)"
              >
                <component :is="row.icon" v-if="row.icon" />
                {{ row.label }}
              </li>
            </ul>
          </div>
        </overlay-scrollbars-component>
      </div>
    </template>
  </v-dropdown>
</template>
