<script setup lang="ts">
import { X } from '@vicons/tabler'
import { onClickOutside } from '@vueuse/core'
import { EBModalPosition, ModalInjectionKey } from './b-modal.types'

defineOptions({
  inheritAttrs: false,
})

/**
 * Default Modal component, not intended to be used
 * directly in templates, but possible.
 *
 * Prefer using the 'useDialog' and 'useMessage' instead.
 */

const props = withDefaults(defineProps<{
  /** Modal State Open/Closed */
  modelValue: boolean
  /** Where the teleport will spawn the modal */
  to?: string
  /** Position on the screen */
  position?: EBModalPosition
  /** Don't dismiss when clicked outside */
  persistent?: boolean
  /** Don't display dark overlay around modal content */
  noOverlay?: boolean
  /** Auto open modal when mounted */
  autoOpen?: boolean
  /** Allow interaction with Modal Background */
  seamless?: boolean
  /** Show close button in modal */
  closeButton?: boolean
}>(), {
  position: EBModalPosition.STANDARD,
  to: 'body',
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
  (event: 'dismiss'): void
}>()

const modalRef = ref<HTMLDivElement>()

const positionClass: Record<EBModalPosition, string> = {
  [EBModalPosition.BOTTOM]: 'b-modal--bottom',
  [EBModalPosition.LEFT]: 'b-modal--left',
  [EBModalPosition.RIGHT]: 'b-modal--right',
  [EBModalPosition.STANDARD]: 'b-modal--standard',
  [EBModalPosition.TOP]: 'b-modal--top',
}

const overlayClass = computed(() => ({
  'b-modal__overlay': !props.noOverlay,
  'pointer-events-none': props.seamless,
}))

function onClose() {
  emit('update:modelValue', false)
  emit('dismiss')
}

function onOpen() {
  emit('update:modelValue', true)
}

onMounted(() => {
  if (props.autoOpen)
    onOpen()
})

onClickOutside(modalRef, () => {
  !props.persistent && onClose()
})

provide(ModalInjectionKey, {
  onClose,
  onOpen,
})

defineExpose({
  onClose,
  onOpen,
})
</script>

<template>
  <teleport :to="to">
    <Transition name="modal" mode="out-in">
      <div
        v-if="modelValue"
        class="b-modal"
        v-bind="$attrs"
        :class="[
          positionClass[position],
          overlayClass,
        ]"
      >
        <div ref="modalRef" class="b-modal__container pointer-events-auto">
          <b-icon v-if="closeButton" class="absolute right-6 top-6 z-1 cursor-pointer" @click="onClose">
            <x />
          </b-icon>
          <slot />
        </div>
      </div>
    </Transition>
  </teleport>
</template>

<style lang="scss" scoped>
.b-modal {
  @apply fixed top-0 left-0 max-w-100vw w-100vw max-h-100vh h-100vh;
  // Avoid changing this value, we need to keep it behind the select and date binders
  @apply z-1000;
  @apply transition-all transition-300 transition-ease;

  &--standard {
    @apply flex items-center justify-center;
  }

  &--top {
    @apply flex items-start justify-center;
  }

  &--bottom {
    @apply flex items-end justify-center;
  }

  &--left {
    @apply flex items-center justify-start;
  }

  &--right {
    @apply flex items-center justify-end;
  }

  &__container {
    @apply transition-all transition-300 transition-300 max-h-full max-w-full p-4 relative;
  }

  &__overlay {
    @apply bg-black bg-opacity-50;
  }
}

.modal-enter-from {
  @apply opacity-0;
}

.modal-leave-to {
  @apply opacity-0;
}

.modal-enter-from .b-modal__container,
.modal-leave-to .b-modal__container {
  @apply transform;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
