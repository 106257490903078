export const longCache = 1000 * 60 * 60 * 24 // 1 day
export const mediumCache = 1000 * 60 * 10 // 10 minutes
export const shortCache = 1000 * 60 * 2 // 2 minutes

export const queryCache = {
  analytics: {
    default: mediumCache,
    images: longCache,
  },
  clubs: mediumCache,
  events: shortCache,
  rosters: longCache,
  users: longCache,
}
