<script setup lang="ts">
import type { ChartData } from '~/utils'

/**
 * This component is a simpler chart card type know as sparkline.
 * Its used for simple and smaller representation of the data
 * in the overview pages.
 */
const props = withDefaults(defineProps<{
  chartData: ChartData
  title: string
  // Whether the data is loading or not.
  isLoading?: boolean
  // Total value to be displayed in the card.
  total?: number | string
  area?: boolean
  colors?: string[]
  type?: 'sparkline' | 'line'
}>(), {
  area: true,
  type: 'sparkline',
})

/**
 * This computed generates the sum of the data to be displayed
 * in the card.
 */
const sum = computed(() => {
  return props.total?.toLocaleString()
    ?? Math.round(props.chartData.y.reduce((acc, entry) => {
      return acc + entry.values.reduce((acc, value) => acc + value, 0)
    }, 0)).toLocaleString()
})
</script>

<template>
  <b-card outlined class="h-40 flex flex-col items-center gap-4 p-4">
    <div class="flex items-center justify-center content-heading">
      {{ title }}
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" class="w-full flex flex-1 gap-2">
        <b-skeleton class="flex-1" height="100%" />
        <div class="flex flex-1 items-center justify-center">
          <b-skeleton height="80%" width="50%" />
        </div>
      </div>
      <div v-else class="w-full flex flex-1 gap-2">
        <div class="h-full flex flex-[2] items-center justify-center">
          <analytics-sparkline v-if="type === 'sparkline'" :data="chartData" :area="area" :colors="colors" />
          <analytics-line-chart v-else :data="chartData" :area="area" :colors="colors" />
        </div>
        <div class="flex flex-1 items-center justify-center page-title">
          <fit
            :options="{
              minSize: 22,
              maxSize: 36,
            }"
          >
            {{ sum ?? 0 }}
          </fit>
        </div>
      </div>
    </transition>
  </b-card>
</template>
