<script setup lang="ts">
import type { ClassType } from '~/utils'

const props = defineProps<{
  error: string | boolean
  hasError: boolean
  id: string
  label?: string
  labelAppend?: string
  labelAppendClass?: ClassType
  labelClass?: ClassType
  showErrorMessage?: boolean
}>()

const errorOpacity = computed(() => (props.error ? 1 : 0))

const labelRef = ref<HTMLLabelElement>()

function focus() {
  labelRef.value?.scrollIntoView(true)
}

defineExpose({
  error: props.error,
  focus,
})
</script>

<template>
  <label ref="labelRef" :for="id">
    <div v-if="label" class="b-field__label">
      <span class="capitalize" :class="labelClass">
        {{ label }}
      </span>
      <span
        v-if="labelAppend"
        :class="labelAppendClass || `text-secondary-text body`"
      >'
        {{ labelAppend }}
      </span>
    </div>
    <slot />
    <div
      v-if="showErrorMessage && hasError"
      class="b-field--error"
    >
      {{ hasError ? error : '' }}
    </div>
  </label>
</template>

<style lang="scss" scoped>
.b-field {
  &__label {
    @apply content-heading mb-3;
  }

  &--error {
    opacity: v-bind(errorOpacity);
    @apply caption text-error mt-2;
  }
}
</style>
