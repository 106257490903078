import { omit } from 'lodash-es'
import type { QueryAggregateStatsOptions, QueryAverageDamagePerGameOptions, QueryCardSelectionRatesOptions, QueryMatchSeriesChartOptions, QueryMatchSeriesStateStatsOptions, QueryPaymentsOverviewOptions, QueryRosterChartOptions, QueryRostersStatusOptions, QueryUsersRegistrationsOptions, QueryUsersStatusOptions } from '~/composables'

export const queryKeys = {
  avatars: {
    key: 'avatars',
    roster: (rosterId: string) => [queryKeys.avatars.key, 'roster', rosterId],
    user: (userId: string) => [queryKeys.avatars.key, 'user', userId],
  },
  rosters: {
    joiningKey: (rosterId?: string | null) => [queryKeys.rosters.key, 'joiningKey', rosterId],
    key: 'rosters',
  },
  stats: {
    brawlstars: {
      averageDamagePerGame: (filters: QueryAverageDamagePerGameOptions) => [queryKeys.stats.key, queryKeys.stats.brawlstars.key, 'averageDamagePerGame', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      brawlers: {
        key: 'brawlers',
        stats: (filters: QueryUsersRegistrationsOptions) => [queryKeys.stats.key, queryKeys.stats.brawlstars.key, queryKeys.stats.brawlstars.brawlers.key, 'stats', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      },
      key: 'brawlstars',
    },
    clashroyale: {
      aggregated: (filters: QueryAggregateStatsOptions) => [queryKeys.stats.key, queryKeys.stats.clashroyale.key, 'aggregated', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      cardSelectionRates: (filters: QueryCardSelectionRatesOptions) => [queryKeys.stats.key, queryKeys.stats.clashroyale.key, 'cardSelectionRates', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      key: 'clashroyale',
    },
    key: 'stats',
    matchSeries: {
      chart: (filters: QueryMatchSeriesChartOptions) => [queryKeys.stats.key, queryKeys.stats.matchSeries.key, queryKeys.stats.matchSeries.chart, 'chart', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      key: 'matchSeries',
      states: (filters: QueryMatchSeriesStateStatsOptions) => [queryKeys.stats.key, queryKeys.stats.matchSeries.key, 'states', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
    },
    payments: {
      key: 'payments',
      overview: (filters: QueryPaymentsOverviewOptions) => [queryKeys.stats.key, queryKeys.stats.payments.key, 'overview', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
    },
    rosters: {
      chart: (filters: QueryRosterChartOptions) => [queryKeys.stats.key, queryKeys.stats.rosters.key, 'chart', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      key: 'rosters',
      status: (filters: QueryRostersStatusOptions) => [queryKeys.stats.key, queryKeys.stats.rosters.key, 'status', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
    },
    users: {
      key: 'users',
      registrations: (filters: QueryUsersRegistrationsOptions) => [queryKeys.stats.key, queryKeys.stats.users.key, queryKeys.stats.users.registrations, 'registrations', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
      status: (filters: QueryUsersStatusOptions) => [queryKeys.stats.key, queryKeys.stats.users.key, 'status', Object.values(omit(filters, 'enabled')).map(filter => filter?.value)],
    },
  },
}
