<script setup lang="ts">
import type { DateRange } from '@cevo/gfinity-api-sdk'
import { defaultChartColors } from '~/utils'
import { queryMatchSeriesChart, queryMatchSeriesStateStats } from '~/composables'

const props = defineProps<{
  title?: string
  event?: string
  segment?: string
  date?: DateRange
  currentNumber?: boolean
}>()

const { t } = useI18n()

const { data: dataLast30Days, isFetching: isFetchingLast30Days } = queryMatchSeriesChart({
  date: computed(() => props.date ?? undefined),
  interval: ref('day'),
})

const { data: dataTotal, isFetching: isFetchingTotal } = queryMatchSeriesStateStats({
  enabled: computed(() => !!props.currentNumber),
})

const title = computed(() => props.title ?? t('analytics.titles.matches'))
const isLoading = computed(() => isFetchingLast30Days.value || isFetchingTotal.value)

const fields = ['trend']
const total = computed(() => {
  if (props.currentNumber)
    return dataTotal?.value?.completedMatches ?? 0
  return dataLast30Days?.value?.total?.total ?? 0
})

const chartData = computed(() => {
  const data = dataLast30Days?.value?.data?.map((entry) => {
    const bye = entry?.bye?.trend ?? 0
    const completed = entry?.completed?.trend ?? 0
    const forfeit = entry?.forfeit?.trend ?? 0
    const tied = entry?.tied?.trend ?? 0
    const overturned = entry?.overturned?.trend ?? 0

    return {
      date: entry.date,
      trend: bye + completed + forfeit + tied + overturned,
    }
  })
  return generateChartData(data, undefined, fields, 'd')
})
</script>

<template>
  <analytics-sparkline-card
    :chart-data="chartData"
    :is-loading="isLoading"
    :title="title"
    :total="total"
    :area="false"
    :colors="[defaultChartColors[2]]"
    class="!h-30"
  />
</template>
