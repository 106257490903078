<script setup lang="ts">
</script>

<template>
  <div v-if="$slots.default" class="b-divider">
    <div class="b-divider__line" />
    <div class="b-divider__content">
      <slot />
    </div>
    <div class="b-divider__line" />
  </div>
  <div v-else class="b-divider">
    <div class="b-divider__line" />
  </div>
</template>

<style lang="scss" scoped>
.b-divider {
  @apply flex justify-center items-center gap-4 w-full;

  &__content {
    @apply content-heading max-w-50% w-full text-center;
  }

  &__line {
    @apply w-full bg-dark-10;
    height: 1px;
  }
}
</style>
