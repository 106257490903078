import type { GetMatchSeriesStateStatsResponse, GetRostersStatusResponse } from '@cevo/gfinity-api-sdk'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export type AnalyticsTimeFrequency = 'h' | 'd'

export interface AnalyticsTableColumnProps {
  title: string
  key: string
  textAlign?: 'left' | 'center' | 'right'
  class?: string | string[]
  truncate?: boolean
  pill?: boolean
  incremental?: boolean
  gauge?: boolean
  percentage?: boolean
  sortable?: boolean
  isBold?: boolean
}

export interface AnalyticsYAxisData {
  name: string
  showSymbol?: boolean
  values: any[]
}

export interface AnalyticsXAxisData {
  type: 'time' | 'value' | 'category' | 'log' | undefined
  values: any[]
}

export interface ChartData {
  x: AnalyticsXAxisData
  y: AnalyticsYAxisData[]
}

export const ChartDataPlaceholder: ChartData = {
  x: {
    type: 'category',
    values: [],
  },
  y: [],
}

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

export const defaultChartColors = [
  '#82ff82',
  '#00d2c8',
  '#0098f0',
  '#004fc4',
  '#5e198f',
  '#91257d',
  '#b86771',
]

export function getLineChartOptions(
  x: AnalyticsXAxisData,
  y: AnalyticsYAxisData[],
  frequency: AnalyticsTimeFrequency,
  t: ReturnType<typeof useI18n>['t'],
  options?: {
    stacked?: boolean
    area?: boolean
    currency?: boolean
    overrideLabels?: Record<string, string>
    colors?: string[]
  },
) {
  return {
    color: options?.colors ?? defaultChartColors,
    grid: {
      bottom: 0,
      containLabel: true,
      left: 24,
      right: 32,
      top: 8,
    },
    series: y.map(series => ({
      areaStyle: (options?.stacked || options?.area) ? {} : undefined,
      data: series.values,
      emphasis: {
        focus: 'series',
      },
      name: (options?.overrideLabels && Object.keys(options.overrideLabels).includes(series.name))
        ? options.overrideLabels[series.name]
        : t(`analytics.data.${series.name}`),
      showSymbol: series.showSymbol ?? false,
      stack: options?.stacked ? 'Total' : undefined,
      type: 'line',
    })),
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: 'axis',
      valueFormatter(value: number) {
        if (options?.currency)
          return formatCurrency(value)
        return value
      },
    },
    xAxis: {
      axisLabel: {
        formatter(value: string) {
          const date = frequency === 'd' ? dayjs(value, 'DD/MM/YY') : dayjs(value, 'DD/MM/YY - LT')
          if (frequency === 'd') {
            if (x.values.length <= 90)
              return date.format('DD/MM/YY')
            if (x.values.length > 90)
              return date.format('MM/YY')
          }
          else {
            if (x.values.length <= 25)
              return date.format('HH:mm')
            if (x.values.length > 24 && x.values.length <= 720)
              return date.format('DD/MM/YY')
            if (x.values.length > 720)
              return date.format('MM/YY')
          }
          return date.format('DD/MM/YY')
        },
      },
      boundaryGap: false,
      data: x.values.map(value => dayjs(value).subtract(dayjs(value).utcOffset(), 'minute').format(frequency === 'd' ? 'DD/MM/YY' : 'DD/MM/YY - LT')),
      splitLine: {
        show: false,
      },
      type: x.type,
    },
    yAxis: {
      axisLabel: {
        formatter(value: number) {
          if (options?.currency)
            return formatCurrency(value)
          return value
        },
      },
      boundaryGap: false,
      splitLine: {
        show: false,
      },
      type: 'value',
    },
  }
}

export function getSparklineOptions(
  x: AnalyticsXAxisData,
  y: AnalyticsYAxisData[],
  frequency: AnalyticsTimeFrequency,
  t: ReturnType<typeof useI18n>['t'],
  options?: {
    stacked?: boolean
    area?: boolean
    colors?: string[]
  },
) {
  return {
    color: options?.colors ?? defaultChartColors,
    grid: {
      borderWidth: 0,
      bottom: 0,
      left: 0,
      right: 0,
      show: true,
      top: 0,
    },
    series: y.map(series => ({
      areaStyle: (options?.stacked || options?.area) ? {} : undefined,
      data: series.values,
      emphasis: {
        focus: 'series',
      },
      name: t(`analytics.data.${series.name}`),
      showSymbol: series.showSymbol ?? false,
      type: 'line',
    })).sort((a, b) => {
      const averageOfA = a.data.reduce((acc, curr) => acc + curr, 0)
      const averageOfB = b.data.reduce((acc, curr) => acc + curr, 0)
      return averageOfB - averageOfA
    }),
    tooltip: {
      axisPointer: {
        animation: false,
      },
      trigger: 'axis',
    },
    xAxis: {
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      boundaryGap: false,
      data: x.values.map(value => dayjs(value).subtract(dayjs(value).utcOffset(), 'minute').format(frequency === 'd' ? 'DD/MM/YY' : 'DD/MM/YY - LT')),
      show: true,
      splitLine: {
        show: false,
      },
      type: 'category',
    },
    yAxis: {
      boundaryGap: false,
      show: false,
      type: 'value',
    },
  }
}

export type ChartDataType = Record<string, any> & { date: string }

export function generateChartData(
  data?: ChartDataType[],
  dateSpan?: string[] | Date[],
  fields?: string[],
  frequency: AnalyticsTimeFrequency = 'd',
  isCumulative?: boolean,
): ChartData {
  const sampleLimit = 365

  // Formatting the BE response into a format that the chart can understand
  const serverData = data?.reduce((acc, entry) => {
    acc.x.values.push(dayjs(entry.date).toISOString())
    fields?.forEach((field, index) => {
      // Init the data format if it doesn't exist
      if (!acc.y[index]) {
        acc.y[index] = {
          name: field,
          values: [],
        }
      }
      // Insert correspondent value into the data format
      // in the right order
      acc.y[index].values.push(entry[field])
    })
    return acc
  }, {
    x: {
      type: 'category',
      values: [],
    },
    y: [],
  } as {
    x: AnalyticsXAxisData
    y: AnalyticsYAxisData[]
  })

  // Calculate the difference between the first and last date
  const from = frequency === 'd' ? dayjs(dateSpan?.[0]).set('hour', 0).set('minute', 0).set('second', 0) : dayjs(dateSpan?.[0])
  const to = frequency === 'd' ? dayjs(dateSpan?.[1]).set('hour', 23).set('minute', 59).set('second', 59) : dayjs(dateSpan?.[1])
  let diff = (frequency === 'd' ? to.add(1, 'second') : to).diff(from, frequency === 'd' ? 'day' : 'hour')

  // If no dateSpan is provided and the data is empty, we want to generate the last 30 periods
  if (!dateSpan?.[0] || !dateSpan?.[1])
    diff = 30
  // If the user selects 1 day only, we want to generate 1 point instead of zero
  if (dateSpan?.[0] && dateSpan?.[1] && diff === 0)
    diff = frequency === 'd' ? 1 : 24

  // If BE returns complete data for the entire date span, we don't need to do anything
  // Just pass it to downsample
  if (serverData && serverData?.y?.length && serverData.y?.every(field => field?.values?.length === diff))
    return downSample(serverData, sampleLimit)

  // If BE returns empty data, we need to generate the data for the entire date span
  if (!serverData || (serverData?.y?.length === 0 && serverData.y?.every(field => field?.values?.length === 0))) {
    const _serverData = serverData ?? {
      x: {
        type: 'category',
        values: [],
      },
      y: [],
    }

    for (let i = 0; i < diff; i++) {
      // Fill the dates in X
      _serverData.x.values.push(from.add(i, frequency === 'd' ? 'day' : 'hour').toISOString())
      fields?.forEach((field, index) => {
        // Init the data format if it doesn't exist
        if (!_serverData.y[index]) {
          _serverData.y[index] = {
            name: field,
            values: [],
          }
        }
        // Fill zero in the correspondent value
        _serverData.y[index].values.push(0)
      })
    }
    // Return the downsampled data
    return downSample(_serverData, sampleLimit)
  }

  // If BE returns partial data, we need to fill this gaps

  // We start with the first date of the date span
  // Calculate the difference between first date of the response and the first date of the date span
  const firstDate = dayjs(serverData.x.values[0])
  // Adding 1 second because DayJs consider 24h diff its not 1 day
  let firstDateDiff = -from.set('hour', 0).set('minute', 0).set('second', 0).add(dayjs().utcOffset(), 'minutes').diff(firstDate.add(1, 'second'), frequency === 'd' ? 'day' : 'hour')

  // If the difference is negative, we invert it
  // There is an old edge case for this, not sure if it still happens
  // but its worth to keep this here
  if (firstDateDiff < 0)
    firstDateDiff = -firstDateDiff

  // If there is difference between the first date of the response and the first date of the date span
  // We need to fill the gaps between the first date of the response and the first date of the date span
  // This case we always fill zeros
  for (let i = 1; i <= firstDateDiff; i++) {
    // Fill the dates in X axis based in the distance from the first response entry
    serverData.x.values.unshift(firstDate.subtract(i, frequency === 'd' ? 'day' : 'hour').toISOString())
    fields?.forEach((field, index) => {
      // Init the data format if it doesn't exist
      if (!serverData.y[index]) {
        serverData.y[index] = {
          name: field,
          values: [],
        }
      }
      // Fill zero in the correspondent value
      serverData.y[index].values.unshift(0)
    })
  }

  // Then we do the same for the last date of the date span
  // Calculate the difference between last date of the response and the last date of the date span
  const lastDate = dayjs(serverData.x.values[serverData.x.values.length - 1])
  const lastDateDiff = to.add(dayjs().utcOffset(), 'minutes').diff(lastDate, frequency === 'd' ? 'day' : 'hour')

  // If there is difference between the last date of the response and the last date of the date span
  // We need to fill these last days with zeros or the last value of the response if cumulative
  for (let i = 1; i <= lastDateDiff; i++) {
    // Fill the dates in X axis based in the distance from the last response entry
    serverData.x.values.push(lastDate.add(i, frequency === 'd' ? 'day' : 'hour').toISOString())
    fields?.forEach((field, index) => {
      // Init the data format if it doesn't exist
      if (!serverData.y[index]) {
        serverData.y[index] = {
          name: field,
          values: [],
        }
      }
      // Fill zero or last value (if cumulative) in the correspondent value
      serverData.y[index].values.push(isCumulative ? serverData.y[index].values[serverData.y[index].values.length - 1] : 0)
    })
  }

  // Finally we downsample the data
  return downSample(serverData, sampleLimit)
}

export function getRandomNumberByDigits(digits: number) {
  return Math.floor(Math.random() * 10 ** digits)
}

export function buildMatchesStatusData(t: ReturnType<typeof useI18n>['t'], matchSeriesStateData?: GetMatchSeriesStateStatsResponse['data'] | null) {
  if (!matchSeriesStateData)
    return

  return [
    {
      label: t('analytics.data.upcoming'),
      value: matchSeriesStateData.upcomingMatches,
    },
    {
      label: t('analytics.data.live'),
      value: matchSeriesStateData.liveMatches,
    },
    {
      label: t('analytics.data.completed'),
      value: matchSeriesStateData.completedMatches,
    },
  ]
}

export function buildRosterStatusData(t: ReturnType<typeof useI18n>['t'], rostersQueryData?: GetRostersStatusResponse['data'] | null) {
  if (!rostersQueryData)
    return
  return [
    {
      label: t('analytics.data.totalRosters'),
      value: rostersQueryData.totalRosters,
    },
    {
      label: t('analytics.data.readyRosters'),
      value: rostersQueryData.readyRosters,
    },
    {
      label: t('analytics.data.notReadyRosters'),
      value: rostersQueryData.notReadyRosters,
    },
  ]
}
