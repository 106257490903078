import type { Log } from '@cevo/gfinity-api-sdk'
import { LoggingEndpoint } from '@cevo/gfinity-api-sdk'
import { useSessionStorage } from '@vueuse/core'
import dayjs from 'dayjs'

//  Interfaces
export interface LogFilters {
  logLevel?: string | undefined
  url?: string | undefined
  message?: string | undefined
  userId?: string | undefined
  date: {
    from?: number | undefined
    to?: number | undefined
  }
  sort: {
    [field: string]: boolean
  }
}

//  Defaults
const defaultFilters: LogFilters = {
  date: {
    from: undefined,
    to: undefined,
  },
  logLevel: undefined,
  message: undefined,
  sort: {
    createdAt: false,
  },
  url: undefined,
  userId: undefined,
}

const currentLog = ref<Log>()

export const useCurrentLog = () => currentLog

export function useLoadLogs(initialFilters?: LogFilters) {
  const route = useRoute()

  const loading = ref(false)
  const page = ref(1)
  const pageCount = ref(1)
  const itemCount = ref(0)
  const config = useSessionStorage(`logs-table@${route.path}`, {
    filters: { ...defaultFilters, ...initialFilters },
    pageSize: 20,
  })
  const { filters, pageSize } = toRefs(config.value)
  const hasAppliedFilters = computed(() => Object.values(filters.value).some((v) => {
    if (typeof v === 'object' && v !== null)
      return Object.values(v).some(Boolean)

    return Boolean(v)
  }))
  const resetFilters = () => {
    Object.assign(filters.value, defaultFilters, initialFilters)
  }
  const logs = ref<Log[]>([])
  async function loadLogs(loadingFilters?: LogFilters) {
    if (loadingFilters)
      filters.value = loadingFilters
    const { date: { from, to }, logLevel, message, sort, url, userId } = filters.value
    loading.value = true

    try {
      const resp = await LoggingEndpoint.getLogs({
        'page[number]': page.value,
        'page[size]': pageSize.value,
        ...(logLevel ? { 'filter[level]': logLevel } : null),
        ...(userId ? { 'filter[user.id]': userId } : null),
        ...(url ? { 'filter[url]': url } : null),
        ...(message ? { 'filter[message]': message } : null),
        ...(from ? { 'filter[date.from]': dayjs(from).format('YYYY-MM-DD') } : null),
        ...(to ? { 'filter[date.to]': dayjs(to).format('YYYY-MM-DD') } : null),
        ...(Object.keys(sort).length > 0 ? { sort: Object.entries(sort).map(([key, value]) => value ? key : `-${key}`).join(',') } : null),
      })
      logs.value = resp.data.data
      pageCount.value = resp.data?.meta?.page?.last || 1
      itemCount.value = resp.data?.meta?.page?.total || 0
    }
    catch (e) {
      console.error(e)
    }
    finally {
      loading.value = false
    }
  }

  async function onUpdatePage(p: number) {
    page.value = p
    await loadLogs()
  }

  async function onUpdatePageSize(p: number) {
    page.value = 1
    pageSize.value = p
    config.value.pageSize = p
    await loadLogs()
  }

  async function onUpdateFilters(f: LogFilters) {
    filters.value = f
    page.value = 1
    config.value.filters = filters.value
    await loadLogs()
  }

  return {
    filters,
    hasAppliedFilters,
    itemCount,
    loading,
    loadLogs,
    logs,
    onUpdateFilters,
    onUpdatePage,
    onUpdatePageSize,
    page,
    pageCount,
    pageSize,
    resetFilters,
  }
}
