import { WebhooksEndpoint } from '@cevo/gfinity-api-sdk'
import type { Webhook, WebhookNotificationLog } from '@cevo/gfinity-api-sdk'
import { useSessionStorage } from '@vueuse/core'

interface Filters {
  url?: string | undefined
  eventType?: string | undefined
}

const currentWebhook = ref<WebhookNotificationLog>()

export const useCurrentWebhook = () => currentWebhook

export function useLoadWebhooks(initialFilters?: Filters) {
  const route = useRoute()

  const defaultFilters: Filters = {
    eventType: undefined,
    url: undefined,
  }

  const loading = ref(false)
  const page = ref(1)
  const pageCount = ref(1)
  const itemCount = ref(0)
  const config = useSessionStorage(`webhooks-table@${route.path}`, {
    filters: { ...defaultFilters, ...initialFilters },
    pageSize: 20,
  })
  const { filters, pageSize } = toRefs(config.value)
  const hasAppliedFilters = computed(() => Object.values(filters.value).some(Boolean))
  const resetFilters = () => {
    Object.assign(filters.value, defaultFilters, initialFilters)
  }
  const webhooks = ref<Webhook[]>([])

  async function loadWebhooks(loadingFilters?: Filters) {
    if (loadingFilters)
      filters.value = loadingFilters

    const { eventType, url } = filters.value
    loading.value = true

    try {
      const resp = await WebhooksEndpoint.getAll({
        'page[number]': page.value,
        'page[size]': pageSize.value,
        ...(url ? { 'filter[target.url]': url } : null),
        ...(eventType ? { 'filter[eventType.name]': eventType } : null),
      })

      webhooks.value = resp.data.data
      itemCount.value = resp.data.meta.page?.total
      pageCount.value = resp.data.meta.page.last > 0 ? resp.data.meta.page.last : 1
    }
    catch (err) {
      console.error(err)
    }
    finally {
      loading.value = false
    }
  }

  const onUpdatePage = (p: number) => {
    page.value = p
    loadWebhooks()
  }

  const onUpdatePageSize = (ps: number) => {
    page.value = 1
    pageSize.value = ps
    config.value.pageSize = ps
    loadWebhooks()
  }

  const onUpdateFilters = (f: Filters) => {
    Object.assign(filters.value, f)
    page.value = 1
    config.value.filters = filters.value
    loadWebhooks()
  }

  return {
    filters,
    hasAppliedFilters,
    itemCount,
    loading,
    loadWebhooks,
    onUpdateFilters,
    onUpdatePage,
    onUpdatePageSize,
    page,
    pageCount,
    pageSize,
    resetFilters,
    webhooks,
  }
}
