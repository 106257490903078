import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)

export class DateUtils {
  public static getMonthNames(length?: number) {
    const months = dayjs.months()
    return length ? months.map(m => m.slice(0, length)) : months
  }

  public static getMonthNamesShort() {
    return dayjs.monthsShort()
  }

  public static getWeekdayNames(length?: number) {
    const weekdays = dayjs.weekdays()
    return length ? weekdays.map(w => w.slice(0, length)) : weekdays
  }

  public static getFrequencyOptions() {
    const { t } = useI18n()
    return [
      { label: t('analytics.frequencySelectors.daily'), value: 'd' },
      { label: t('analytics.frequencySelectors.hourly'), value: 'h' },
    ]
  }

  public static format(date: Date | string | undefined | null, format = 'DD MMM \'YY @ HH:mm') {
    if (!date)
      return '-'
    return dayjs(date).format(format)
  }
}
