<script setup lang="ts">
import { Check, CircleX, X } from '@vicons/tabler'
import type { FormRules } from './b-form.types'
import { requiredField } from '~/utils'
import { useDetectAutofill, useFormChild } from '~/composables'
import type { ClassType } from '~/utils'
import BField from '~/components/base/b-field.vue'

const props = withDefaults(defineProps<{
  modelValue: string | number | undefined
  name?: string
  autocomplete?: string
  big?: boolean
  clearable?: boolean
  disabled?: boolean
  id?: string
  label?: string
  labelAppend?: string
  labelAppendClass?: ClassType
  labelClass?: ClassType
  placeholder?: string
  placeholderAppend?: string
  rules?: FormRules
  showErrorMessage?: boolean
  showFeedbackIcon?: boolean
  type?: string
  textArea?: boolean
  required?: boolean
  readonly?: boolean
  showPassword?: boolean
  loading?: boolean

  // Number Related Props
  rows?: number | string
  min?: number
  max?: number
  step?: number
}>(), {
  autocomplete: 'off',
  rows: 2,
})

const emit = defineEmits(['update:modelValue'])
const inputType = ref(props.type ?? 'text')

// We are creating `id` here because we need to pass it to `useDetectAutofill`
const vm = getCurrentInstance()
const id = `input-${vm?.uid}`

const { isAutoFilled } = useDetectAutofill(id)

const inputRef = ref<HTMLInputElement>()

// Inject form context
const {
  error,
  hasError,
  validate,
} = useFormChild({
  modelValue: computed(() => props.modelValue),
  rules: computed(() => {
    const rules = props.rules ? [...props.rules] : []
    if (props.required && !isAutoFilled.value)
      rules.unshift(requiredField)
    return rules
  }),
})

const containerClass = computed(() => ({
  'b-field__container': true,
  'b-field__container--big': props.big,
  'b-field__container--disabled': props.disabled,
}))

const inputClass = computed(() => ({
  'b-input': true,
  'b-input--big': props.big,
  'b-input--disabled': props.disabled,
}))

const textAreaHeight = computed(() => {
  const rows = Number(props.rows)
  return rows * (16 / 15)
})

function focus() {
  inputRef.value?.focus()
}

function update(e: Event) {
  let value: number | string = (e.target as HTMLInputElement).value
  if (inputType.value === 'number' && typeof value === 'string' && value !== '')
    value = Number(value.trim())
  isAutoFilled.value = false
  emit('update:modelValue', value)
}

function onClear() {
  isAutoFilled.value = false
  emit('update:modelValue', '')
}

function onShowPassword() {
  inputType.value = inputType.value === 'password' ? 'text' : 'password'
}

defineExpose({
  error,
  focus,
  validate,
})
</script>

<template>
  <b-field
    :id="id"
    :error="error"
    :has-error="hasError"
    :label-append-class="labelAppendClass"
    :label-append="labelAppend"
    :label-class="labelClass"
    :label="label"
    :show-error-message="showErrorMessage"
  >
    <div class="flex flex-1 gap-2">
      <div
        :class="[containerClass, {
          '!items-start py-2': textArea,
        }]"
      >
        <slot name="prepend" />
        <textarea
          v-if="textArea"
          :id="id"
          :name="name ?? id"
          :class="inputClass"
          :value="modelValue"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :type="inputType"
          :readonly="readonly"
          resize="vertical"
          :style="rows ? `height: ${textAreaHeight}rem` : ''"
          @input="update($event)"
        />
        <input
          v-else
          :id="id"
          ref="inputRef"
          :name="name ?? id"
          :class="inputClass"
          :value="modelValue"
          :autocomplete="autocomplete"
          :disabled="disabled"
          :type="inputType"
          :min="min"
          :max="max"
          :step="step"
          :readonly="readonly"
          @input="update($event)"
        >
        <div
          v-if="!isAutoFilled && (modelValue === undefined || modelValue === '' || modelValue === null)"
          class="b-input__placeholder"
        >
          {{ placeholder ?? $t('input.placeholder.default') }}
        </div>
        <div
          v-if="placeholderAppend"
          class="b-input__placeholder-append"
        >
          {{ placeholderAppend }}
        </div>
        <div v-if="$slots.suffix" class="ml-1">
          <slot name="suffix" />
        </div>
        <b-spinner v-if="loading" size="14" class="ml-1" />
        <div
          v-if="!isAutoFilled && (showFeedbackIcon || clearable || showPassword)"
          class="b-input__feedback-icon"
        >
          <b-icon
            v-if="showFeedbackIcon && error && modelValue !== undefined"
            color="rgb(var(--color-error))"
          >
            <x />
          </b-icon>
          <b-icon
            v-else-if="showFeedbackIcon && !error && modelValue"
            color="rgb(var(--color-positive))"
          >
            <check />
          </b-icon>
          <div
            v-if="clearable && !disabled && !loading"
            class="b-input__clear-btn"
            @click="onClear"
          >
            <b-icon size="16">
              <circle-x />
            </b-icon>
          </div>
          <div v-if="showPassword" class="b-input__show-password-btn" @click="onShowPassword">
            <div v-if="inputType === 'password'" class="i-tabler-eye text-xl" />
            <div v-else-if="inputType === 'text'" class="i-tabler-eye-off text-xl" />
          </div>
        </div>
        <slot name="append" />
      </div>
      <slot name="append-outside" />
    </div>
  </b-field>
</template>

<style lang="scss" scoped>
.b-input {
  @apply w-full min-w-0 important-outline-0 select-none;
  @apply b-0 body h-9;
  @apply p-0 text-background-text;

  &--disabled {
    @apply text-dark-50;
  }

  &:focus-visible {
    outline: none;
  }

  &__placeholder {
    @apply text-dark-50 absolute body capitalize;
  }

  &__placeholder-append {
    @apply text-dark-50 absolute right-2 body;
  }

  &__feedback-icon {
    @apply flex items-center;
  }

  &__clear-btn {
    @apply opacity-0 cursor-pointer color-dark-25 transition transition-350 hover:color-dark-40;
  }

  &__show-password-btn {
    @apply cursor-pointer color-dark-25 transition transition-350 hover:color-dark-40;
  }
}

.b-field {
  &__container {
    @apply relative b-1 b-dark-10 b-rd-1;
    @apply flex flex-1 px-2 items-center bg-background;

    textarea {
      &::-webkit-scrollbar {
        all: unset;
      }
    }

    &--disabled {
      @apply bg-dark-5 cursor-not-allowed;

      .b-input {
        @apply cursor-not-allowed;

        &__placeholder {
          @apply text-dark-50;
        }
      }
    }

    &:hover {
      .b-input__clear-btn {
        @apply opacity-100 cursor-pointer;
      }
    }
  }
}
</style>
