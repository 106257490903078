import type { GetUsersPayload, GetUsersResponse, UserPublic, UserStatus } from '@cevo/gfinity-api-sdk'
import { StaticEndpoint, UsersEndpoint } from '@cevo/gfinity-api-sdk'
import { useSessionStorage } from '@vueuse/core'
import type { AxiosResponse } from 'axios'
import { useQuery } from '@tanstack/vue-query'
import type { SortState } from '~/utils'
import { handleError } from '~/plugins'

export interface ILoadUsers {
  initialFilters?: UserFilters
  initialSorter?: Sorter
  getAllRecords?: boolean
  infinitePages?: boolean
  initialPageSize?: number
  sessionId?: string
}

export interface UserFilters {
  event?: string | undefined
  username?: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  email?: string | undefined
  status?: UserStatus | undefined
  hasAdminPermissions?: 0 | 1 | '0' | '1' | undefined
  userId?: string | undefined
  externalIdProvider?: string | undefined
  externalIdValue?: string | undefined
}

interface Sorter {
  order: string | boolean
  property: string | null
}

const currentUser = ref<UserPublic>()

export const useCurrentUser = () => currentUser

export function useLoadUsers({
  getAllRecords = false,
  infinitePages = false,
  initialFilters,
  initialPageSize = 20,
  initialSorter,
  sessionId = '',
}: ILoadUsers = {}) {
  const loading = ref(false)
  const page = ref(1)
  const pageCount = ref(1)
  const itemCount = ref(0)
  const pageSize = ref(initialPageSize)
  const users = ref<UserPublic[]>([])
  const route = useRoute()

  const defaultFilters: UserFilters = {
    email: undefined,
    event: undefined,
    externalIdProvider: undefined,
    externalIdValue: undefined,
    firstName: undefined,
    hasAdminPermissions: undefined,
    lastName: undefined,
    status: undefined,
    username: undefined,
  }

  const config = useSessionStorage(`users-table@${route.path}${sessionId ?? ''}`, {
    filters: { ...defaultFilters, ...initialFilters },
    pageSize: initialPageSize,
  })
  const { filters } = toRefs(config.value)
  const hasAppliedFilters = computed(() =>
    Object.values(filters.value).some(Boolean),
  )
  const resetFilters = () => {
    Object.assign(filters.value, defaultFilters, initialFilters)
  }

  const sorter = ref<Sorter>({
    order: initialSorter?.order ?? false,
    property: initialSorter?.property ?? null,
  })

  const loadUsers = async () => {
    const { email, externalIdProvider, externalIdValue, firstName, hasAdminPermissions, lastName, status, userId, username } = filters.value

    loading.value = true
    try {
      const params: GetUsersPayload = {}
      if (userId)
        params['filter[id]'] = userId

      if (username)
        params['filter[username]'] = username

      if (firstName)
        params['filter[firstName]'] = firstName

      if (lastName)
        params['filter[lastName]'] = lastName

      if (email)
        params['filter[email]'] = email

      if (status)
        params['filter[status]'] = status

      if (hasAdminPermissions !== null)
        params['filter[hasAdminPermissions]'] = hasAdminPermissions

      if (externalIdProvider !== null)
        params['filter[externalId.provider]'] = externalIdProvider

      if (externalIdValue !== null)
        params['filter[externalId.value]'] = externalIdValue

      if (sorter.value.order && sorter.value.property)
        params.sort = `${sorter.value.order === 'descend' ? '-' : ''}${sorter.value.property}` as GetUsersPayload['sort']

      if (userId) {
        const resp = await UsersEndpoint.getUser(userId)
        users.value = resp.data.data ? [resp.data.data] : []
        pageCount.value = 1
        itemCount.value = 1
      }
      else {
        const resp = await UsersEndpoint.getUsers({
          'page[number]': page.value,
          'page[size]': pageSize.value,
          ...params,
        })

        pageCount.value = resp.data.meta.page.last > 0 ? resp.data.meta.page.last : 1
        itemCount.value = resp.data.meta.page.total

        if (page.value > 1 && infinitePages) {
          users.value = users.value.concat(resp.data.data)
          return
        }

        users.value = resp.data.data

        let allPages: AxiosResponse<GetUsersResponse, any>[] = []
        if (getAllRecords && pageCount.value > 1) {
          const remainingPages = Array.from(Array(pageCount.value - 1).keys())
          allPages = await Promise.all(
            remainingPages.map((page: number) => {
              return UsersEndpoint.getUsers({
                ...params,
                'page[number]': page + 2, // page 1 is already loaded and key is 0 based
                'page[size]': pageSize.value,
              })
            }),
          )
        }

        if (getAllRecords)
          users.value = [...users.value, ...allPages.map(({ data }) => data.data).flat()]
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      loading.value = false
    }
  }

  const onUpdateFilters = async (updatedFilters: UserFilters) => {
    Object.assign(filters.value, updatedFilters)
    page.value = 1
    config.value.filters = filters.value
    await loadUsers()
  }

  const onUpdatePageSize = (ps: number) => {
    page.value = 1
    pageSize.value = ps
    config.value.pageSize = ps
    loadUsers()
  }

  const onUpdateSorter = (value: SortState) => {
    sorter.value = {
      order: value.order,
      property: value.columnKey.toString(),
    }
    page.value = 1
    loadUsers()
  }

  const onUpdatePage = async (p: number) => {
    page.value = p
    await loadUsers()
  }

  const onDeleteUsers = async (userIds: string[]) => {
    const deletedUserIds = new Set()
    const responses = await Promise.allSettled(
      userIds.map(id => UsersEndpoint.deleteUser(id)),
    )

    responses.forEach((response, index) => {
      if (response.status === 'fulfilled')
        deletedUserIds.add(userIds[index])

      else
        handleError(response.reason)
    })

    users.value = users.value.filter(user => !deletedUserIds.has(user.id))
  }
  return {
    filters,
    hasAppliedFilters,
    itemCount,
    loading,
    loadUsers,
    onDeleteUsers,
    onUpdateFilters,
    onUpdatePage,
    onUpdatePageSize,
    onUpdateSorter,
    page,
    pageCount,
    pageSize,
    resetFilters,
    users,
  }
}

export function queryUserAvatar(userId: string, enabled?: boolean) {
  const onError = (error: any) => {
    if (error?.response?.status === 404)
      return null

    handleError(error)
  }

  const getUserAvatar = async () => {
    try {
      const { data } = await StaticEndpoint.getUserAvatar(userId)
      if (!data?.data)
        return null
      return data.data
    }
    catch (error: any) {
      onError(error)
      return null
    }
  }

  return useQuery({
    enabled,
    gcTime: queryCache.users,
    queryFn: getUserAvatar,
    queryKey: queryKeys.avatars.user(userId),
    staleTime: queryCache.users,
  })
}
