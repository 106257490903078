import type { MatchSeriesConfigurationPayload, SingleMatchesConfigurationPayload } from '@cevo/gfinity-api-sdk'
import { CadenceType, PerformedByType, PlayerSelectionType } from '@cevo/gfinity-api-sdk'

export const defaultSegmentConfiguration: {
  matchSeries: MatchSeriesConfigurationPayload
  singleMatch: SingleMatchesConfigurationPayload
} = {
  matchSeries: {
    checkins: {
      enabled: false,
      maxMissed: {
        consecutive: undefined,
      },
      performedBy: PerformedByType.ALL,
      timeWindow: {
        from: undefined,
        to: undefined,
      },
    },
    playerSelection: {
      allowRepeatedPlayers: true,
      allowUpdate: true,
      timeWindow: {
        from: undefined,
        to: undefined,
      },
      type: PlayerSelectionType.ALL,
    },
    rosterSize: {
      max: undefined,
      min: undefined,
    },
    singleMatches: {
      cadence: CadenceType.MANUAL,
      delay: 0,
    },
  },
  singleMatch: {
    checkins: {
      enabled: false,
      timeWindow: {
        from: undefined,
        to: undefined,
      },
    },
    playerSelection: {
      allowUpdate: true,
      timeWindow: {
        from: undefined,
        to: undefined,
      },
      type: PlayerSelectionType.ALL,
    },
    rosterSize: {
      max: undefined,
      min: undefined,
    },
  },
}
