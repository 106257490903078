<script setup lang="ts">
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import { useAppStore } from '~/store'
import logo from '~/assets/logo.svg'

const props = withDefaults(defineProps<{
  show: boolean
}>(), {
  show: false,
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const appStore = useAppStore()
const showDrawerManager = ref(false)

function onClose() {
  showDrawerManager.value = false
  setTimeout(() => {
    appStore.setDrawerShown(false)
    emit('close')
  }, 100)
}

watch(() => props.show, (value) => {
  if (!value) {
    showDrawerManager.value = false
    return
  }
  setTimeout(() => showDrawerManager.value = true, 100)
})
</script>

<template>
  <div>
    <transition name="fade">
      <div v-if="appStore.isDrawerShown" class="fixed left-0 top-0 z-1000 h-full w-full bg-dark-100 bg-opacity-30" @click="onClose">
        <transition name="drawer-menu">
          <div v-if="showDrawerManager" class="relative z-1001 h-full max-w-100% w-70 bg-background p-4" @click.stop>
            <div class="mb-8 flex items-center justify-between">
              <img height="25" :src="logo" alt="Logo Symbol">
              <div class="i-tabler-x text-2xl" @click="onClose" />
            </div>

            <overlay-scrollbars-component
              defer
              class="max-h-[calc(100%-3.5rem)] w-full"
              :options="{
                scrollbars: {
                  autoHide: 'scroll',
                },
                overflow: {
                  x: 'hidden',
                },
              }"
            >
              <slot />
            </overlay-scrollbars-component>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.drawer-menu-enter-active,
.drawer-menu-leave-active {
  transition: all 0.25s ease;
  margin-left: 0;
}

.drawer-menu-enter-from,
.drawer-menu-leave-to {
  @apply -ml-70;
}
</style>
