export enum EBModalPosition {
  STANDARD = 'standard',
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ModalInjection {
  onClose: () => void
  onOpen: () => void
}
export const ModalInjectionKey = Symbol('ModalInjectionKey')
