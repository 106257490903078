import { debounce } from 'lodash-es'
import type { ComponentInternalInstance } from 'vue'
import type { FormRules } from '~/components/base/b-form.types'
import { formKey } from '~/components/base/b-form.types'

// Interfaces to be used internally only
// don't export them
interface ComponentInternalInstanceWithExposedBinding extends ComponentInternalInstance {
  provides: Record<symbol, BindingComponent>
}
interface BindingComponent {
  bindComponent: (component: ComponentInternalInstance | null) => void
  unbindComponent: (component: ComponentInternalInstance | null) => void
}

export function useFormChild(args: {
  modelValue?: Ref<any>
  rules?: Ref<FormRules | undefined>
}) {
  const { modelValue, rules } = args
  const vm = getCurrentInstance()

  const id = `input-${vm?.uid}`

  if ((vm as ComponentInternalInstanceWithExposedBinding).provides?.[formKey as symbol])
    inject(formKey)

  onMounted(() => {
    const form = (vm as ComponentInternalInstanceWithExposedBinding).provides[formKey as symbol]
    if (form)
      form.bindComponent(vm)
  })

  onBeforeUnmount(() => {
    const form = (vm as ComponentInternalInstanceWithExposedBinding).provides[formKey as symbol]
    if (form)
      form.unbindComponent(vm)
  })

  const error = ref<string | false>(false)

  function validate(): boolean {
    if (rules?.value) {
      for (const rule of rules.value) {
        const result = rule(modelValue?.value)
        if (result !== true) {
          error.value = result
          return false
        }
      }
    }
    error.value = false
    return true
  }

  const hasError = ref(false)

  const debouncedValidate = debounce(() => {
    hasError.value = validate() !== true
  }, 150)

  watch([() => modelValue?.value, error], debouncedValidate)

  return {
    error,
    hasError,
    id,
    validate,
  }
}
