import type { AnalyticsXAxisData, AnalyticsYAxisData, ChartData } from '~/utils'

export function downSample(chartData?: ChartData, threshold?: number): ChartData {
  if (!chartData || !chartData.x || !chartData.y || !chartData.y.length || !chartData.x.values || !chartData.x.values.length || !threshold)
    return chartData ?? ChartDataPlaceholder
  const { x, y } = chartData
  const length = x.values.length
  const downSampledX: AnalyticsXAxisData = {
    type: x.type,
    values: [],
  }
  const downSampledY: AnalyticsYAxisData[] = []

  if (threshold <= 0 || length <= threshold)
    return chartData

  let rest = length % threshold
  let restCounted = 0
  const sampleSize = Math.floor(length / threshold)
  for (let i = 0; i < threshold; i++) {
    const sampleIndex = i * sampleSize + restCounted
    downSampledX.values.push(x.values[sampleIndex])
    if (rest > 0) {
      rest--
      restCounted++
    }
  }

  rest = length % threshold
  restCounted = 0
  y.forEach((yAxisData, index) => {
    downSampledY[index] = {
      name: yAxisData.name,
      showSymbol: yAxisData.showSymbol,
      values: [],
    }

    for (let i = 0; i < threshold; i++) {
      const initialSampleIndex = i * sampleSize + restCounted
      const adjustedSampleSize = rest > 0 ? sampleSize + 1 : sampleSize
      // const sumY = 0

      let highest = 0
      for (let j = 0; j < adjustedSampleSize; j++) {
        if (yAxisData.values[initialSampleIndex + j] > highest)
          highest = yAxisData.values[initialSampleIndex + j]
      }

      downSampledY[index].values.push(highest)
      if (rest > 0) {
        rest--
        restCounted++
      }
    }
  })

  return {
    x: downSampledX,
    y: downSampledY,
  }
}
