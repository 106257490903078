<script setup lang="ts">
import type { AnalyticsXAxisData, AnalyticsYAxisData } from '~/utils'

/**
 * This component is used to display a sparkline chart.
 * It is used for simple and smaller representation of the data
 * in the overview pages.
 */
const props = withDefaults(defineProps<{
  data: {
    x: AnalyticsXAxisData
    y: AnalyticsYAxisData[]
  }
  area?: boolean
  colors?: string[]
}>(), {
  area: true,
})

const { t } = useI18n()

/**
 * Sparklines has its own options generator for ECharts component.
 */
const option = computed(() => {
  const options = getSparklineOptions(props.data.x, props.data.y, 'd', t, {
    area: props.area ? true : undefined,
    colors: props.colors?.length ? props.colors : undefined,
  })

  return options
})
</script>

<template>
  <v-chart
    v-if="!!option && !!data"
    :option="option"
    :autoresize="true"
    :init-options="{
      renderer: 'svg',
    }"
  />
</template>
