import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import {
  SVGRenderer,
} from 'echarts/renderers'
import {
  LineChart,
} from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components'
import type { App } from 'vue'

export function installCharts(app: App<Element>) {
  use([
    SVGRenderer,
    LineChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    ToolboxComponent,
  ])

  app.component('VChart', ECharts)
}
