<script setup lang="ts">
import type { AnalyticsTimeFrequency, AnalyticsXAxisData, AnalyticsYAxisData } from '~/utils'

/**
 * This component is used to display a line chart.
 */

const props = withDefaults(defineProps<{
  // The data to be rendered in the chart.
  data: {
    x: AnalyticsXAxisData
    y: AnalyticsYAxisData[]
  }
  // The frequency of the data.
  frequency?: AnalyticsTimeFrequency
  // Whether to display s stacked view or not.
  stacked?: boolean
  // Whether to display an area chart or not.
  area?: boolean
  // Whether the data is currency or not.
  currency?: boolean
  // Override the following labels of the chart.
  overrideLabels?: Record<string, string>
  colors?: string[]
}>(), {
  area: true,
  frequency: 'd',
})

const { t } = useI18n()

/**
 * This computed generates the line chart options to be
 * passed into the EChart component.
 */
const _options = computed(() => {
  const options = getLineChartOptions(props.data.x, props.data.y, props.frequency, t, {
    area: props.area,
    colors: props.colors,
    currency: props.currency,
    overrideLabels: props.overrideLabels,
    stacked: props.stacked,
  })
  return options
})
</script>

<template>
  <v-chart
    v-if="!!_options && !!data"
    :option="_options"
    :autoresize="true"
    :init-options="{
      renderer: 'svg',
    }"
  />
</template>
