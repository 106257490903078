<script setup lang="ts">
import { AlertCircle, CircleCheck, InfoCircle, X } from '@vicons/tabler'
import { Message, MessageType } from '~/plugins'
import BCard from '~/components/base/b-card.vue'

/**
 * BDialog is a Wrapper around BModal to used only in
 * Dialog function. Don't trust its direct usage in
 * templates.
 */

const props = defineProps<{
  modelValue?: boolean
  /** Message to be displayed */
  message?: string
  /**
   * Type Message Type will change Icon and Color
   * before message
   */
  type?: MessageType
  /** Title to be displayed */
  title?: string
  /** Show a default close button */
  closeButton?: boolean
  /** Don't dismiss when clicked outside */
  persistent?: boolean
  /** Boolean for Ok button, string to define its text */
  ok?: boolean | string
  /** Ok Button Click Callback */
  onOk?: (...args: any) => boolean | Promise<boolean>
  /** Turns Ok Button into a Warning Button */
  warningOk?: boolean
  /** Boolean for Cancel Button, string to define its text */
  cancel?: boolean | string
  /** Cancel Button Click Callback */
  onCancel?: (...args: any) => boolean | Promise<boolean>
  /** Dismiss Callback, works like a finally hook */
  onDismiss?: (...args: any) => any | Promise<any>
  /**
   * Boolean for automatic dismiss after 3 seconds
   * Or pass a number to define time
   */
  timeout?: boolean | number
  /** Classes to be passed to the default BCard */
  classes?: string
  /** Don't display dark overlay around the modal */
  noOverlay?: boolean
  /** Allow interaction with Modal Background */
  seamless?: boolean
  /** Modal Position Style */
  positionStyle?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'open'): void
  (e: 'close'): void
}>()

const isOpen = ref(false)
const isLoading = ref(false)
const showTimeout = ref(false)
const bCardRef = ref<InstanceType<typeof BCard>>()

const timeoutForCss = computed(() => props.timeout
  ? props.timeout === true
    ? 3000
    : props.timeout
  : 3000)

function onOpen() {
  isOpen.value = true
  if (!props.modelValue)
    emit('update:modelValue', true)
  emit('open')
  showTimeout.value = true
  if (props.timeout) {
    setTimeout(() => {
      onClose()
      showTimeout.value = false
    }, props.timeout === true
      ? 3000
      : props.timeout)
  }
}

function onClose() {
  isOpen.value = false
  if (props.modelValue)
    emit('update:modelValue', false)
  emit('close')
  _onDismiss()
}

function _onDismiss() {
  if (props.onDismiss)
    props.onDismiss()
}

async function onClickOk() {
  if (props.onOk) {
    isLoading.value = true
    const confirmation = await props.onOk()
    isLoading.value = false
    if (confirmation !== false)
      onClose()
  }
  else { onClose() }
}

async function onClickCancel() {
  if (props.onCancel) {
    isLoading.value = true
    const confirmation = await props.onCancel()
    isLoading.value = false
    if (confirmation !== false)
      onClose()
  }
  else { onClose() }
}

const messageIconColor = computed(() => {
  if (!props.type)
    return 'current'

  switch (props.type) {
    case MessageType.SUCCESS:
      return 'color-positive'
    case MessageType.ERROR:
      return 'color-error'
    case MessageType.WARNING:
      return 'color-warning'
    case MessageType.INFO:
      return 'color-info'
    default:
      return 'color-current'
  }
})

const progressColor = computed(() => {
  if (!props.type)
    return 'current'

  switch (props.type) {
    case MessageType.SUCCESS:
      return 'success'
    case MessageType.ERROR:
      return 'error'
    case MessageType.WARNING:
      return 'warning'
    case MessageType.INFO:
      return 'info'
    default:
      return undefined
  }
})

const style = ref()
onMounted(() => {
  const head = document.getElementsByTagName('head')[0]
  style.value = document.createElement('style')
  style.value.type = 'text/css'
  head.appendChild(style.value)
  style.value.innerHTML = `
    .b-dialog .progress.active:before {
      animation: progress ${timeoutForCss.value}ms linear forwards;
    }
  `
})

onBeforeUnmount(() => {
  const head = document.getElementsByTagName('head')[0]
  head.removeChild(style.value)
})

// Position Update Logic
const bottom = ref<number>()
function updateCardBottom() {
  if (bCardRef.value) {
    const { bottom: b } = bCardRef.value.$el.getBoundingClientRect()
    bottom.value = b
  }
}
watch(bCardRef, () => {
  updateCardBottom()
}, { immediate: true })
const positionStyle = ref(props.positionStyle)
async function updatePosition(index: number) {
  if (index === 0)
    positionStyle.value = 'margin-top: 16px'
  else positionStyle.value = `margin-top: ${Message.stack[index - 1]?.bottom ?? 16}px`
  await nextTick()
  updateCardBottom()
}

defineExpose({
  bottom,
  onClose,
  onOpen,
  updatePosition,
})

watch(() => props.modelValue, (value) => {
  if (value && !isOpen.value)
    onOpen()
  else if (!value && isOpen.value)
    onClose()
})
</script>

<template>
  <b-modal
    v-model="isOpen"
    class="p-2 xs:p-4 !transition-margin-300"
    :persistent="persistent"
    :no-overlay="noOverlay"
    :seamless="seamless"
    :style="positionStyle"
    @dismiss="onClose"
  >
    <b-card
      ref="bCardRef"
      class="b-dialog"
      :class="[classes, {
        'xs:min-w-85': ok && cancel,
        'overflow-hidden': !!type,
        'overflow-visible': !type,
      }]"
    >
      <div v-if="message && closeButton && !ok" class="flex items-center gap-4" :class="{ 'flex-col': title }">
        <div v-if="title" class="w-full flex items-center" :class="{ 'justify-center': !closeButton, 'justify-between': closeButton }">
          <div class="content-heading">
            {{ title }}
          </div>
          <div v-if="closeButton">
            <div class="cursor-pointer select-none" @click="onClose">
              <b-icon size="16">
                <x />
              </b-icon>
            </div>
          </div>
        </div>
        <div class="b-dialog__message">
          <div v-if="!!type" :class="messageIconColor" class="flex items-center">
            <b-icon size="24">
              <circle-check v-if="type === MessageType.SUCCESS" />
              <alert-circle v-if="type === MessageType.WARNING" />
            </b-icon>
          </div>
          <div :class="!!type ? 'text-4 font-500' : ''">
            {{ message }}
          </div>
        </div>
        <div v-if="!title" class="flex cursor-pointer select-none items-center" @click="onClose">
          <b-icon size="16">
            <x />
          </b-icon>
        </div>
      </div>
      <div v-else-if="message && (ok || cancel)" class="flex flex-col gap-4">
        <div v-if="title" class="content-heading">
          {{ title }}
        </div>
        <div v-if="message" :class="!!type ? 'text-4 font-500' : ''">
          {{ message }}
        </div>
        <div v-if="ok || cancel" class="w-full flex gap-4">
          <div v-if="ok" class="flex-1">
            <b-btn class="w-full capitalize" :primary="!warningOk" :error="warningOk" :disabled="isLoading" @click="onClickOk">
              {{ ok === true ? $t('common.confirm') : ok }}
            </b-btn>
          </div>
          <div v-if="cancel" class="flex-1">
            <b-btn class="w-full capitalize" ghost :disabled="isLoading" @click="onClickCancel">
              {{ cancel === true ? $t('common.rawClose') : cancel }}
            </b-btn>
          </div>
        </div>
      </div>
      <div v-else-if="message" class="flex flex-col justify-center gap-4">
        <div v-if="title" class="content-heading">
          {{ title }}
        </div>
        <div v-if="message" class="b-dialog__message" :class="!!type ? 'text-4 font-500' : ''">
          <div v-if="!!type" :class="messageIconColor">
            <b-icon size="24">
              <circle-check v-if="type === MessageType.SUCCESS" />
              <alert-circle v-if="type === MessageType.WARNING || type === MessageType.ERROR" />
              <info-circle v-if="type === MessageType.INFO" />
            </b-icon>
          </div>
          <div>{{ message }}</div>
        </div>
      </div>
      <div v-else class="flex items-center gap-4" :class="{ 'flex-col': title || closeButton }">
        <div
          v-if="title || closeButton" class="w-full flex items-center"
          :class="{
            'justify-center': title && !closeButton,
            'justify-between': title && closeButton,
            'justify-end': !title && closeButton,
          }"
        >
          <div v-if="title" class="content-heading">
            {{ title }}
          </div>
          <div v-if="closeButton">
            <div class="cursor-pointer select-none" @click="onClose">
              <b-icon size="16">
                <x />
              </b-icon>
            </div>
          </div>
        </div>
        <div class="w-full">
          <slot />
        </div>
      </div>
      <div v-if="timeout" class="progress" :class="[{ active: showTimeout }, progressColor]" />
    </b-card>
  </b-modal>
</template>

<style lang="scss">
.b-dialog {
  @apply p-4 shadow;

  &__message {
    @apply flex items-center gap-2;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;

    &:before {
      // TODO: Change color when bug
      // https://github.com/vuejs/core/issues/8520
      // is fixed and bind timing inside css
      @apply bg-primary;
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }

    &.success {
      &:before {
        @apply important-bg-positive;
      }
    }

    &.error {
      &:before {
        @apply important-bg-error;
      }
    }

    &.warning {
      &:before {
        @apply important-bg-warning;
      }
    }

    &.info {
      &:before {
        @apply important-bg-info;
      }
    }
  }
}

@keyframes progress {
  100% {
    right: 100%;
  }
}
</style>
