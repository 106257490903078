import { capitalize } from 'vue'
import { i18n } from '~/plugins'

const { t } = i18n.global

export function getGenderOptions() {
  return [
    { label: capitalize(t('users.gender.male')), value: 'male' },
    { label: capitalize(t('users.gender.female')), value: 'female' },
    { label: capitalize(t('users.gender.unspecified')), value: 'not_specified' },
  ]
}
