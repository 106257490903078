<script setup lang="ts">
import type { DateRange } from '@cevo/gfinity-api-sdk'
import { queryUsersRegistrations, queryUsersStatus } from '~/composables'
import type { AnalyticsXAxisData, AnalyticsYAxisData } from '~/utils'
import { defaultChartColors } from '~/utils'

const props = defineProps<{
  title?: string
  event?: string
  segment?: string
  date?: DateRange
  currentNumber?: boolean
}>()

const { t } = useI18n()

const { data: dataLast30Days, isFetching: isFetchingLast30Days } = queryUsersRegistrations({
  date: computed(() => props.date ?? undefined),
  event: computed(() => props.event ?? undefined),
  interval: ref('day'),
  segment: computed(() => props.segment ?? undefined),
})

const { data: dataTotal, isFetching: isFetchingTotal } = queryUsersStatus({
  enabled: computed(() => !!props.currentNumber),
})

const title = computed(() => props.title ?? t('analytics.titles.playerRegistrations'))
const isLoading = computed(() => isFetchingLast30Days.value || isFetchingTotal.value)

const fields = ['trend']
const total = computed(() => {
  if (props.currentNumber)
    return dataTotal?.value?.totalPlayers ?? 0
  return dataLast30Days?.value?.total ?? 0
})
const chartData = computed<{
  x: AnalyticsXAxisData
  y: AnalyticsYAxisData[]
}>(() => generateChartData(dataLast30Days?.value?.data, undefined, fields, 'd') ?? {
  x: {
    type: 'category',
    values: [],
  },
  y: [],
})
</script>

<template>
  <analytics-sparkline-card
    :chart-data="chartData"
    :is-loading="isLoading"
    :title="title"
    :total="total"
    :area="false"
    :colors="[defaultChartColors[0]]"
    class="!h-30"
  />
</template>
