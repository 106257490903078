import { X } from '@vicons/tabler'
import type { App, ComponentPublicInstance } from 'vue'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue'
import { Dropdown, Tooltip } from 'floating-vue'
import type { MessageType } from './message'
import { installI18n } from './i18n'
import { installRouter } from './router'
import { installVueQuery } from './vue-query'
import BBtn from '~/components/base/b-btn.vue'
import BCard from '~/components/base/b-card.vue'
import BDialog from '~/components/base/b-dialog.vue'
import bIconVue from '~/components/base/b-icon.vue'
import type { EBModalPosition } from '~/components/base/b-modal.types'
import BModal from '~/components/base/b-modal.vue'
import 'floating-vue/dist/style.css'

export function useDialog(options: {
  /**
   * Message to be Displayed
   * This will not be displayed if Custom Component is defined
   */
  message?: string

  /**
   * Message type will change default color and icon before message
   */
  type?: MessageType

  /**
   * 'true' for to Show Ok Button
   * 'string' for Ok Button Text
   * This is not displayed in Custom Components
   */
  ok?: boolean | string

  /**
   * Ok Button Click Callback
   * This don't have effect in Custom Components
   *
   * You can return false in the function to hold
   * the dialog open after an error for example
   */
  onOk?: (...args: any) => any | Promise<any>

  /**
   * Turns Ok Button into a Warning Button
   */
  warningOk?: boolean

  /**
   * 'true' for to Show Cancel Button
   * 'string' for Cancel Button Text
   * This is not displayed in Custom Components
   */
  cancel?: boolean | string

  /**
   * Cancel Button Click Callback
   * This don't have effect in Custom Components
   */
  onCancel?: (...args: any) => any | Promise<any>

  /**
   * Dismiss Callback
   * Called when the modal is closed like a finally hook
   * This is called even in Custom Components
   */
  onDismiss?: (...args: any) => any | Promise<any>

  // Custom Component Props
  /**
   * Custom Component to be displayed
   * This will not include the default Base Card
   * unless useCard is true.
   * Most of props above are disabled when this
   * is set. Check their docs.
   */
  component?: Component

  /**
   * Props to be passed into Custom Component
   * Don't have effect if Custom Component is not defined
   */
  componentProps?: Record<string, any>

  /**
   * Whether to use Base Card in Custom Component
   */
  useCard?: boolean

  // ModalProps
  /**
   * Position of the Modal in the Screen
   */
  position?: EBModalPosition
  /**
   * Whether the modal is dismissible by clicking outside
   */
  persistent?: boolean
  /**
   * Include the default close button or not
   * This is disabled in case of Custom Component
   * without default card
   */
  closeButton?: boolean
  /**
   * Title to be displayed in the card
   * This is disabled in case of Custom Component
   * without default card
   */
  title?: string
  /**
   * Auto dismiss modal after 3 seconds if true
   * Or pass a number to define the desired time
   */
  timeout?: boolean | number
  /**
   * Classes to be passed into Default Card
   * This is disabled in case of custom component
   * without default card
   */
  classes?: string
  /**
   * Styles to be passed into Default Card Body
   */
  style?: string
  /**
   * Don't display dark overlay around modal
   */
  noOverlay?: boolean
  /**
   * Allow interact with the page behind the modal
   */
  seamless?: boolean
  /**
   * Style to be passed into the modal position
   */
  positionStyle?: string
}) {
  // The strategy is spawn another Vue App over the screen
  // and destroy it after. Not using HTML dialog due to
  // difficulties of controlling events, prop drilling and
  // directives and animations from out of the component
  const tempDiv = document.createElement('div')
  // Callback function to be called after dialog is closed
  function unmount(app: App) {
    app.unmount()
    tempDiv.remove()
  }

  // Splitting Dialog Props from Modal only props
  const { component, componentProps, useCard: useModal, ...dialogProps } = options
  const customProps = component ? componentProps : dialogProps

  // In case a Custom Component is passed, we need to spawn
  // it inside the BModal component and place a control over it

  const customComponent = component && defineComponent({
    name: 'CustomDialog',
    setup() {
      const isOpen = ref(false)
      return () => h(
        BModal,
        {
          'autoOpen': true,
          'modelValue': isOpen.value,
          'noOverlay': options.noOverlay,
          // As v-model don't works with render functions
          // This will
          'onDismiss': () => {
            // Callback for dismissing in case the close
            // order comes from inside the component
            options.onDismiss && options.onDismiss()

            isOpen.value = false
          },
          // we do it as old vue times
          'onUpdate:modelValue': (value: boolean) => {
            if (value && options.timeout) {
              setTimeout(() => {
                isOpen.value = false
              }, options.timeout === true
                ? 3000
                : options.timeout)
            }

            isOpen.value = value
          },
        },
        useModal
          // In case user wants to use the default BCard
          // we place it around the Custom Component and
          // position title and close button if true
          ? () => h(
              BCard,
              {
                class: `${options.classes ?? ''}`,

              },
              () => h(
                OverlayScrollbarsComponent,
                {
                  class: 'max-w-full flex flex-col b-rd-2',
                  defer: true,
                  options: {
                    scrollbars: {
                      autoHide: 'scroll',
                    },
                  },
                  style: 'max-height: calc(100vh - 2rem)',
                },
                () => [
                  options.closeButton || !!options.title
                    ? h(
                      'div',
                      {
                        class: `flex items-center ${options.closeButton && !!options.title
                        ? 'justify-between'
                        : (options.closeButton && !options.title
                          ? 'justify-end'
                          : (!options.closeButton && !!options.title
                            ? 'justify-center'
                            : ''))}`,
                      },
                      [
                        options.title
                          ? h('div', { class: `${options.title ? 'content-heading' : ''}` }, options.title)
                          : undefined,
                        options.closeButton
                          ? h(
                            BBtn,
                            {
                              onClick: () => {
                                isOpen.value = false
                              },
                            },
                            () => h(
                              bIconVue,
                              () => h(X),
                            ),
                          )
                          : undefined,
                      ],
                    )
                    : null,
                  // Custom Component and Custom Props
                  // inside BCard
                  h(component, customProps),
                ],
              ),
            )
          // With no BCard you can pass you own floating container
          // and own Closing Mechanism using v-close-modal directive
          // Remembering BModal is still around it
          : () => h(
              component,
              customProps,
            ),
      )
    },
  })

  // We spawn the custom created component if Custom Component
  // is defined or we spawn a predefined Dialog Component
  // containing Message, Title, Ok, Cancel and Close...

  const app = createApp(customComponent ?? BDialog, {
    ...dialogProps,
    onDismiss: async () => {
      if (options?.onDismiss)
        await options.onDismiss()
      // When Modal is dismissed we unmount the custom app
      // after 3 seconds, just giving time for the animation
      // and free memory, don't leave callbacks inside a custom
      // component, always use the onDismiss callback as it can
      // be terminate before resolves in case of an async callback
      setTimeout(() => unmount(app), 3000)
    },
  })

  installRouter(app)
  installI18n(app)
  installVueQuery(app)

  app.directive('close-modal', vCloseModal)
  app.component('VDropdown', Dropdown)
  app.component('VTooltip', Tooltip)

  // We mount the new App over the screen
  const mountedApp = app.mount(tempDiv) as ComponentPublicInstance & {
    onOpen: () => void
  }

  // Append it to the body
  document.body.appendChild(mountedApp.$el)

  // In case of Custom Component not being set
  // we can call onOpen to open it automatically
  // In case of Custom Component is set this is
  // handled on BModal itself
  mountedApp.onOpen && mountedApp.onOpen()

  return mountedApp
}
