import { camelCase } from 'lodash-es'
import { EventsEndpoint, MatchesEndpoint } from '@cevo/gfinity-api-sdk'
import { Message, handleError } from '~/plugins'
import type { JobFinishedResponse } from '~/store/jobs'

export function useJobsWebsocket() {
  const router = useTenantRouter()
  const redirectJobLink = ref()

  const { t } = useI18n()

  const getRedirectLinkForTypeSegment = async (eventJob: any, jobPayload: any): Promise<string> => {
    let link = ''
    try {
      const segmentId = jobPayload.attributes?.id || jobPayload.id || null
      if (!segmentId)
        return link

      const resSegment = await EventsEndpoint.getSegmentById(segmentId)
      const segment = resSegment.data.data
      const eventId = segment.event?.id

      if (!segment || !eventId)
        return link

      link = `/events/${eventId}/segment/${segmentId}`

      switch (eventJob.name) {
        case 'matches.bracket_generation':
        case 'matches.bracket_destroy':
        case 'matches.next_round_generation':
        case 'matches.seed_matches.randomly':
          link += '/matches'
          break
      }
    }
    catch (e) {
      handleError(e)
    }
    return link
  }

  const handle = async (eventData: JobFinishedResponse) => {
    try {
      const eventJob = eventData.data
      const event = eventData.event
      redirectJobLink.value = router.routePath('')

      if (!eventJob)
        return

      const resJob = await MatchesEndpoint.getJob(eventJob.id)
      const dataJob = resJob.data.data

      let { data: jobPayload } = JSON.parse(dataJob.payload)
      if (Array.isArray(jobPayload))
        jobPayload = jobPayload.shift()

      if (jobPayload?.type === 'Segment')
        redirectJobLink.value += await getRedirectLinkForTypeSegment(eventJob, jobPayload)

      const isSuccess = event !== 'jobs:job_failed'
      let text = t(`job.finished.matches.finished.${isSuccess ? 'success' : 'error'}`, {
        name: t(`job.${eventJob.name.split('.').map((str: string) => camelCase(str)).join('.')}`),
      })
      if (!isSuccess && dataJob.failureMessage)
        text = dataJob.failureMessage

      if (isSuccess)
        Message.info(text)
      else
        Message.error(text)
    }
    catch (e) {
      handleError(e)
    }
  }

  return {
    handle,
  }
}
