import { defineStore } from 'pinia'

export interface JobFinishedDataResponse {
  id: string
  name: string
  createdBy: {
    id: string
  }
  date: string
}

export interface JobFinishedResponse {
  event: string
  data: JobFinishedDataResponse
}

export interface JobFinishedState {
  job: JobFinishedResponse
}

export const useJobsStore = defineStore('jobs', () => {
  const job = ref<JobFinishedResponse>()

  function updateFinishedJob(payload: JobFinishedResponse) {
    setTimeout(() => { job.value = payload }, 1000)
  }

  return {
    job,
    updateFinishedJob,
  }
})
