<script setup lang="ts">
import type { Event, Image } from '@cevo/gfinity-api-sdk'
import { StaticEndpoint } from '@cevo/gfinity-api-sdk'
import { handleExpected404 } from '~/plugins'

const props = defineProps<{
  event: Event
}>()

const { locale } = useI18n()

const tenantRouter = useTenantRouter()

const el = ref<HTMLElement>()
const eventId = computed<string>(() => props.event?.id)
const headerImages = ref<Image | null>()

async function getImage(id: string, size: string) {
  try {
    const { data: staticData } = await StaticEndpoint.getImages({
      'filter[key]': `in-app/event/${id}/header/${size}`,
    })

    if (staticData?.data?.length)
      return staticData.data[0]

    return null
  }
  catch (error) {
    handleExpected404(error)
  }
}

// Image States
const headerImage = computed(() => {
  const headerWidth = 768
  if (!headerImages.value)
    return {}

  return {
    '1x': headerImages.value.image.templates?.original?.replace('{width}', headerWidth.toString())?.replace('{height}', '0'),
    '2x': headerImages.value.image.templates?.original?.replace('{width}', (headerWidth * 2).toString())?.replace('{height}', '0'),
    '3x': headerImages.value.image.templates?.original?.replace('{width}', (headerWidth * 3).toString())?.replace('{height}', '0'),
  }
})
const headerImageSrcset = computed(() => {
  if (headerImage.value['2x'] && headerImage.value['3x'])
    return `${headerImage.value['1x']} 1x, ${headerImage.value['2x']} 2x, ${headerImage.value['3x']} 3x`
  else if (headerImage.value['2x'])
    return `${headerImage.value['1x']} 1x, ${headerImage.value['2x']} 2x`
  else
    return `${headerImage.value['1x']} 1x`
})

const eventStartDate = computed(() => DateUtils.format(props.event.date?.from, 'DD MMM YYYY'))
const eventEndDate = computed(() => DateUtils.format(props.event.date?.to, 'DD MMM YYYY'))

function gotoEvent() {
  el.value?.classList.toggle('active')

  el.value?.addEventListener('transitionend', () => {
    tenantRouter.push(`/events/${props.event.id}`)
    el.value?.classList.toggle('active')
  }, { once: true })
}

watch(eventId, async (id) => {
  if (!id)
    return

  headerImages.value = await getImage(id, '16x9')
}, { immediate: true })
</script>

<template>
  <div
    v-if="event"
    ref="el"
    role="link"
    class="event-card"
    tabindex="0"
    @click="gotoEvent"
  >
    <div class="event-card__cover-image">
      <div class="aspect-16/9 flex items-center justify-center">
        <img v-if="headerImage['1x']" rel="preload" :src="headerImage['1x']" :srcset="headerImageSrcset">
        <div v-else class="h-full w-full rounded-t-[1rem] bg-dark-10" />
      </div>
    </div>

    <div class="event-card__subcard">
      <div class="mb-2 flex flex-auto">
        <div class="truncate content-heading">
          {{ event.configuration?.[locale]?.name || event.name }}
        </div>
      </div>
      <div class="flex items-center gap-x-3">
        <div class="flex flex-1 items-center">
          <span class="body">{{ eventStartDate }} - {{ eventEndDate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import './event-card.scss';
</style>
