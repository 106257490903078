<script setup lang="ts">
import type { DateRange } from '@cevo/gfinity-api-sdk'
import { queryRosterChart, queryRostersStatus } from '~/composables/queries/stats'
import { defaultChartColors } from '~/utils'

const props = defineProps<{
  title?: string
  event?: string
  segment?: string
  date?: DateRange
  currentNumber?: boolean
}>()

const { t } = useI18n()

const { data: dataLast30Days, isFetching: isFetchingLast30Days } = queryRosterChart({
  date: computed(() => props.date ?? undefined),
  interval: ref('day'),
})

const { data: dataTotal, isFetching: isFetchingTotal } = queryRostersStatus({
  enabled: computed(() => !!props.currentNumber),
})

const title = computed(() => props.title ?? t('analytics.titles.teamsRegistrations'))
const isLoading = computed(() => isFetchingLast30Days.value || isFetchingTotal.value)

const fields = ['trend']
const total = computed(() => {
  if (props.currentNumber)
    return dataTotal?.value?.totalRosters ?? 0
  return dataLast30Days?.value?.total?.total ?? 0
})
const chartData = computed(() => {
  const data = dataLast30Days?.value?.data?.map(entry => ({
    date: entry.date,
    trend: entry.trend.banned + entry.trend.not_ready + entry.trend.ready,
  }))
  return generateChartData(data, undefined, fields, 'd')
})
</script>

<template>
  <analytics-sparkline-card
    :chart-data="chartData"
    :is-loading="isLoading"
    :title="title"
    :total="total"
    :area="false"
    :colors="[defaultChartColors[1]]"
    class="!h-30"
  />
</template>
