<script setup lang="ts">
import type { FittyInstance, FittyOptions } from 'fitty'
import fitty from 'fitty'

const props = defineProps<{
  options: FittyOptions
}>()

const $_fitty = ref<FittyInstance>()
const fit = ref<HTMLElement>()

onUnmounted(() => {
  $_fitty.value?.unsubscribe()
})

onMounted(() => {
  if (fit.value)
    $_fitty.value = fitty(fit.value, props.options)
})
</script>

<template>
  <span ref="fit" class="fit">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.fit {
  display: inline-block;
  white-space: nowrap;
}
</style>
