import { useAppStore } from '~/store'

export function getTenantArray() {
  const appStore = useAppStore()
  const tenant = appStore.tenant
  return tenant ? tenant.split('/') : []
}

export class Tenant {
  static isGlobal() {
    return getTenantArray()[0] === '_global_'
  }

  static isSupercell() {
    return getTenantArray()[0] === 'supercell'
  }

  static isBrawlStars() {
    return getTenantArray()[1] === 'brawlstars'
  }

  static isClashRoyale() {
    return getTenantArray()[1] === 'clashroyale'
  }

  static isTesting() {
    return getTenantArray()[2] === 'testing'
  }
}
