<script setup lang="ts">
const props = defineProps({
  color: String,
  hexColor: String,
  size: {
    default: '5em',
    type: String,
  },
})

function getVar(color: string | undefined) {
  if (color && color.indexOf('var(') === 0)
    return color

  return undefined
}

function getHex(color: string | undefined) {
  if (color) {
    if (color[0] === '#')
      return color
    else if (color.length === 3 || color.length === 6)
      return `#${color}`
  }
  return undefined
}

function isRgb(color: string | undefined) {
  if (color) {
    if (color[0] === '#')
      return false
    else if (color.length === 3 || color.length === 6)
      return false
    else if (color.startsWith('rgb'))
      return color
  }
  return false
}

const styles = computed(() => ({ color: getVar(props.color) || getHex(props.hexColor) || isRgb(props.color) || 'var(--color-primary)' }))
</script>

<template>
  <svg class="b-spinner" :style="styles" :width="size" :height="size" viewBox="25 25 50 50">
    <circle class="b-spinner__path" cx="50" cy="50" r="20" fill="none" stroke="currentColor" stroke-width="5" stroke-miterlimit="10" />
  </svg>
</template>

<style lang="scss">
@import './b-spinner.scss';
</style>
