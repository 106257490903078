import type { MaybeRef } from 'vue'
import type { MaybePromise } from '~/utils/maybe-promise'
import { i18n } from '~/plugins'

const { t } = i18n.global

interface MassActionRejected {
  reason: PromiseRejectedResult
  selected: string
}

export async function executeMassAction<T>(args: {
  promises: Promise<T>[]
  selected: MaybeRef<string[]>
  onOk?: MaybePromise<void>
  onError?: MaybePromise<string[]>
  errorMessage?: string
}) {
  const { errorMessage, promises } = args
  const selected = toValue(args.selected)
  const response = await Promise.allSettled(promises)
  const errors = response.reduce((acc: MassActionRejected[], res, index) => {
    if (res.status === 'rejected') {
      acc.push({
        reason: res.reason as PromiseRejectedResult,
        selected: selected[index],
      })
    }
    return acc
  }, [])

  if (errors.length === 0) {
    await args.onOk?.()
    return
  }

  const message = errorMessage ?? t('alert.partialError')
  if (errors.length > 1) {
    handleError(message)
    return args.onError?.(errors.map(e => e.selected))
  }

  handleError(errors[0].reason, message)
  return args.onError?.(errors.map(e => e.selected))
}
