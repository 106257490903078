import { i18n } from '~/plugins'

const { t } = i18n.global

export function hasLowercase(value: string) {
  return /[a-z]/.test(value)
}

export function hasUppercase(value: string) {
  return /[A-Z]/.test(value)
}

export function hasNumber(value: string) {
  return /[0-9]/.test(value)
}

export function requiredField(v: any): true | string {
  return (v !== undefined && v !== null && v !== '') || t('error.required')
}

export function hasValidEmail(value: string): true | string {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(value) || t('validation.email')
}

export function hasValidName(value: string): true | string {
  if (value.length < 2)
    return t('validation.minLength', { n: 2 })
  if (value.length > 32)
    return t('validation.maxLength', { n: 32 })
  return true
}

export function greaterThanZero(v: any): true | string {
  return (
    v !== undefined
    && v !== null
    && v > 0
  ) || t('error.greaterThanZero')
}

export function greaterThanOrEqualsZero(v: any): true | string {
  return (
    v !== undefined
    && v !== null
    && v >= 0
  ) || t('error.greaterThanOrEqualsZero')
}

export function greaterThanOrEqualsTwo(v: any): true | string {
  return (
    v !== undefined
    && v !== null
    && v >= 2
  ) || t('error.greaterThanOrEqualsTwo')
}

export function isValidChannelUrl(value: any): true | string {
  const YT_CHANNEL_REGEX = /^https?:\/\/(?:www\.)?youtube.com\/channel\/([\w\-]+)(?:\?.*|)$/

  const TWITCH_REGEX = /^https?:\/\/(?:www\.)?twitch.tv\/([\w\-]+)(?:\?.*|)$/

  return (
    YT_CHANNEL_REGEX.test(value)
    || TWITCH_REGEX.test(value)
  ) || t('error.invalidChannelUrl')
}

export function isValidatePassword(password: string) {
  if (password.length < 8)
    return t('validation.minLength', { n: 8 })

  if (!hasLowercase(password))
    return t('validation.hasLowercase')

  if (!hasUppercase(password))
    return t('validation.hasUppercase')

  if (!hasNumber(password))
    return t('validation.hasNumber')

  return true
}
