import { useSessionStorage } from '@vueuse/core'
import { EventsEndpoint } from '@cevo/gfinity-api-sdk'
import type { Event, EventStatusType, GetEventsPayload, GetEventsResponse, Roster } from '@cevo/gfinity-api-sdk'
import type { AxiosResponse } from 'axios'
import type { SortState } from '~/utils'

export interface ILoadEvent {
  initialFilters?: EventFilters
  initialSorter?: Sorter
  initialPageSize?: number
  getAllRecords?: boolean
}

interface EventFilters {
  status?: EventStatusType | undefined
  name?: string | undefined
}

interface Sorter {
  order: string | boolean
  property: string | null
}

const currentEvent = ref<Roster>()

export const useCurrentEvent = () => currentEvent

export function useLoadEvents({
  getAllRecords = false,
  initialFilters,
  initialPageSize = 20,
  initialSorter,
}: ILoadEvent = {}) {
  const route = useRoute()

  const defaultFilters: EventFilters = {
    name: undefined,
    status: undefined,
  }

  const defaultSorter: Sorter = {
    order: initialSorter?.order ?? false,
    property: initialSorter?.property ?? null,
  }

  const loading = ref(false)
  const page = ref(1)
  const pageCount = ref(1)
  const itemCount = ref(0)

  const sorter = ref<Sorter>({
    order: initialSorter?.order ?? false,
    property: initialSorter?.property ?? null,
  })
  const config = useSessionStorage(`events-table1@${route.path}`, {
    filters: { ...defaultFilters, ...initialFilters },
    pageSize: initialPageSize,
    sorter: { ...defaultSorter, ...initialSorter },
  })
  const { filters, pageSize } = toRefs(config.value)
  const hasAppliedFilters = computed(() =>
    Object.values(filters.value).some(Boolean),
  )
  const resetFilters = () => {
    Object.assign(filters.value, defaultFilters, initialFilters)
  }

  const events = ref<Event[]>([])

  async function loadEvents(loadingFilters?: EventFilters) {
    if (loadingFilters)
      filters.value = loadingFilters

    const { name, status } = filters.value
    loading.value = true

    try {
      const params: GetEventsPayload = {
        'page[number]': page.value,
        'page[size]': pageSize.value,
      }

      if (status)
        params['filter[status]'] = status

      if (name)
        params['filter[name]'] = name

      if (sorter.value.order && sorter.value.property)
        params.sort = `${sorter.value.order === 'descend' ? '-' : ''}${sorter.value.property}` as GetEventsPayload['sort']

      const resp = await EventsEndpoint.getEvents(params)

      pageCount.value = resp.data.meta.page.last > 0 ? resp.data.meta.page.last : 1
      itemCount.value = resp.data.meta.page.total
      events.value = resp.data.data

      let allPages: AxiosResponse<GetEventsResponse, any>[] = []
      if (getAllRecords && pageCount.value > 1) {
        const remainingPages = Array.from(Array(pageCount.value - 1).keys())
        allPages = await Promise.all(
          remainingPages.map((page: number) => EventsEndpoint.getEvents({
            ...params,
            'page[number]': page + 2,
          })),
        )
      }

      if (getAllRecords)
        events.value = [...events.value, ...allPages.map(({ data }) => data.data).flat()]
    }
    catch (err) {
      console.error(err)
    }
    finally {
      loading.value = false
    }
  }

  const onUpdatePage = (p: number) => {
    page.value = p
    loadEvents()
  }

  const onUpdatePageSize = (ps: number) => {
    page.value = 1
    pageSize.value = ps
    config.value.pageSize = ps
    loadEvents()
  }

  const onUpdateFilters = (f: EventFilters) => {
    Object.assign(filters.value, f)
    config.value.filters = filters.value
    page.value = 1
    loadEvents()
  }

  const onUpdateSorter = (s: SortState) => {
    sorter.value = {
      order: s.order,
      property: s.columnKey.toString(),
    }
    config.value.sorter = sorter.value
    loadEvents()
  }

  const getSelectBoxOptions = () => {
    return events.value.map(event => ({
      label: event.name,
      value: event.id,
    }))
  }

  return {
    events,
    filters,
    getSelectBoxOptions,
    hasAppliedFilters,
    itemCount,
    loadEvents,
    loading,
    onUpdateFilters,
    onUpdatePage,
    onUpdatePageSize,
    onUpdateSorter,
    page,
    pageCount,
    pageSize,
    resetFilters,
  }
}
