<script setup lang="ts">
import { useAppStore } from './store'
import { usePreferencesStore } from './store/preferences'
import { useUserStore } from '~/store/users'

const socketComp = useSocket()
const appStore = useAppStore()
const userStore = useUserStore()
const preferencesStore = usePreferencesStore()

const loading = ref(true)
const socket = ref()

const userId = computed(() => userStore.loggedUser?.id)
const computedTenant = computed(() => appStore.tenant)

watch (() => userId.value, () => {
  if (!userId.value || !computedTenant.value)
    return

  socket.value = socketComp.createSocket(computedTenant.value, userId.value)
})

onMounted(async () => {
  try {
    await userStore.getProfile()
    await preferencesStore.loadLanguages()
  }
  catch (err) {}
  finally {
    loading.value = false
  }
})
</script>

<template>
  <div v-if="loading" class="min-h-screen flex items-center justify-center">
    <b-spinner />
  </div>
  <router-view v-else />
</template>
