import { createApp } from 'vue'
import { GfinityApi } from '@cevo/gfinity-api-sdk'

// @ts-expect-error the library is not typed
import JsonViewer from 'vue3-json-viewer'
import * as Sentry from '@sentry/vue'
import { Dropdown, VTooltip } from 'floating-vue'

// @ts-expect-error the library is not typed
import { RecycleScroller } from 'vue-virtual-scroller'
import * as pjson from '../package.json'
import App from './App.vue'
import { installPinia } from './store'
import { installCharts, installVueQuery } from './plugins'
import { installI18n } from './plugins/i18n'
import { installRouter } from './plugins/router'

import '@unocss/reset/normalize.css'
import 'virtual:uno.css'
import './styles/main.scss'
import 'vue3-json-viewer/dist/index.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'floating-vue/dist/style.css'

const gfinityApiConfig: {
  tenant: string
  url?: string
  env?: string
  logUrl?: string
} = {
  tenant: import.meta.env.VITE_X_ORIGIN as string,
}

if (import.meta.env.VITE_API_BASE_URL)
  gfinityApiConfig.url = import.meta.env.VITE_API_BASE_URL as string

if (import.meta.env.VITE_API_LOG_URL)
  gfinityApiConfig.logUrl = import.meta.env.VITE_API_LOG_URL as string

if (import.meta.env.VITE_ENVIRONMENT)
  gfinityApiConfig.env = import.meta.env.VITE_ENVIRONMENT as string

GfinityApi.configure(gfinityApiConfig)

const app = createApp(App)
const router = installRouter(app)

const release = import.meta.env.VITE_ENVIRONMENT === 'dev'
  ? `uap-dev@${pjson.version}`
  : `uap@${pjson.version}`

// Remove this if when you want to debug local with Sentry
if (import.meta.env.VITE_SENTRY_ENV && import.meta.env.VITE_SENTRY_ENV !== 'local') {
  Sentry.init({
    app,
    debug: import.meta.env.VITE_ENVIRONMENT === 'dev',
    dsn: 'https://6fbd476fceea41fe99a8d6335161d690@o1432106.ingest.sentry.io/6783097',
    environment: import.meta.env.VITE_SENTRY_ENV ?? 'local',
    ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.'],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [<string>import.meta.env.VITE_API_BASE_URL.replace('https://', '')],
      }),
    ],
    release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  })
}

app.use(JsonViewer)

installPinia(app)
installI18n(app)

app.directive('close-modal', vCloseModal)
app.directive('tooltip', VTooltip)
app.component('RecycleScroller', RecycleScroller)
app.component('VDropdown', Dropdown)

// Install Vue Query
installVueQuery(app)

// Install e-charts
installCharts(app)

app.mount('#app')
