<script lang="ts" setup>
import { isMenuActive, menuOptions } from '~/utils'
import { useAppStore } from '~/store'
import type { MenuItem } from '~/utils'

const props = defineProps({
  drawerOptions: {
    default: () => menuOptions(),
    type: Object as PropType<MenuItem[]>,
  },
})

const appStore = useAppStore()
const tenantRouter = useTenantRouter()
const route = useRoute()

const tenant = computed(() => appStore.tenant)
const options = computed<MenuItem[]>(() => tenant.value ? props.drawerOptions : [])

function getUrlRoute(item: MenuItem) {
  if (!item.path || item.disabled)
    return ''
  return item.path
}
</script>

<template>
  <div class="hidden h-full w-70 flex-shrink-0 overflow-y-overlay b-r-1 b-toolbar b-solid transition-all duration-200 ta:block">
    <div class="drawer-menu">
      <template v-for="item in options" :key="item.key">
        <div v-if="item.show === false ? false : true">
          <h3 v-if="item.children?.length" class="drawer-menu__header">
            {{ item.label.toUpperCase() }}
          </h3>
          <router-link
            v-else :to="tenantRouter.routePath(getUrlRoute(item))"
            class="drawer-menu__item"
            :class="{
              'drawer-menu__item__actionable': !!item.path,
              'drawer-menu__item__active': isMenuActive(item, route.name),
              'drawer-menu__item__disabled': item.disabled,
            }"
          >
            {{ item.label }}
          </router-link>

          <template v-if="item.children?.length">
            <router-link
              v-for="subitem in item.children"
              :key="subitem.key"
              class="drawer-menu__item"
              :class="{
                'drawer-menu__item__actionable': !!subitem.path,
                'drawer-menu__item__active': isMenuActive(subitem, route.name, item),
                'drawer-menu__item__disabled': subitem.disabled,
              }"
              :to="tenantRouter.routePath(getUrlRoute(subitem))"
            >
              {{ subitem.label }}
            </router-link>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.drawer-menu {
  @apply lh-[calc(22.4rem/16)] text-dark-80 capitalize;
  @apply px-4 pt-2 pb-4 font-500 text-4;

  &__header {
    @apply mt-6 block pl-2 text-green-180 text-3 font-700;
  }

  &__item {
    @apply text-dark-80 text-4 font-400 px-2 py-1 b-rd-2 mt-1 transition duration-300;

    &__disabled {
      @apply text-dark-20 pointer-events-none;
    }

    &__actionable {
      @apply block;
      &:hover {
        @apply bg-green-20;
      }

      &:active {
        @apply bg-green-40;
      }
    }

    &__active {
      @apply bg-green-20 text-green-180;
    }
  }

}
</style>
