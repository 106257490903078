import type { Socket } from 'socket.io-client'
import { io } from 'socket.io-client'
import { useJobsStore } from '~/store/jobs'

let socket: Socket<any, any>
export function useSocket() {
  const jobStore = useJobsStore()

  const createSocket = (tenant: string, userId: string) => {
    socket = io(`${import.meta.env.VITE_SOCKET_URL}/${tenant}/admin`, {
      transports: ['websocket'],
    })

    socket.on('connect', () => {
      socket.emit('user', {
        id: userId,
      })
    })

    socket.on('update', (response: any) => {
      if (response.data?.createdBy?.id !== userId)
        return

      switch (response.event) {
        case 'jobs:job_finished':
        case 'jobs:job_failed':
          jobStore.updateFinishedJob(response)
          break
      }
    })

    return socket
  }

  const getSocket = () => {
    return socket
  }
  return {
    createSocket,
    getSocket,
  }
}
