import type { NotificationTemplate } from '@cevo/gfinity-api-sdk'
import type { Ref } from 'vue'

const currentTemplate = ref<NotificationTemplate>()

export const useCurrentTemplate = () => currentTemplate

export function useTemplateContentPreview(input: Ref<NotificationTemplate | string | null | undefined>) {
  return computed(() => {
    if (!input.value)
      return ''

    let content = ''
    if (typeof input.value === 'string')
      content = input.value
    else if (typeof input.value === 'object')
      content = input.value.content ?? ''

    const iframeResizerScript = '<script type="text/javascript" src="/js/iframeResizer.contentWindow.min.js"><\/script>'
    const index = content.indexOf('</body>')
    return index > -1
      ? content.replace('</body>', `${iframeResizerScript}</body>`)
      : content + iframeResizerScript
  })
}
