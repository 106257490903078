import { useQuery } from '@tanstack/vue-query'
import type { DateRange, FilterInterval, GetMatchSeriesChartResponse, MatchSeriesChartResponse, UserRegistrationResponse } from '@cevo/gfinity-api-sdk'
import { MatchesEndpoint, RostersEndpoint, StatsEndpoint } from '@cevo/gfinity-api-sdk'

// Users
export interface QueryUsersRegistrationsOptions {
  date?: Ref<DateRange | undefined>
  enabled?: Ref<boolean | undefined>
  event?: Ref<string | undefined>
  segment?: Ref<string | undefined>
  interval?: Ref<FilterInterval | undefined>
}

export function queryUsersRegistrations(options: QueryUsersRegistrationsOptions = {}) {
  const { date, enabled, event, interval, segment } = options

  const _enabled = computed(() => {
    if (date) {
      if (date.value?.from && date.value?.to) {
        if (enabled)
          return enabled.value
        else
          return true
      }
      else {
        if (!date.value)
          return true
        return false
      }
    }
    else if (enabled) { return enabled.value }
    else {
      return true
    }
  })

  async function getRegistrations(): Promise<{ data: UserRegistrationResponse[], total: number } | null> {
    const { data: firstPage } = await StatsEndpoint.getUserRegistrations({
      'filter[date.from]': date?.value?.from ?? null,
      'filter[date.to]': date?.value?.to ?? null,
      'filter[event]': event?.value,
      'filter[segment]': segment?.value,
      'interval': interval?.value,
    })

    if (!firstPage?.data)
      return null

    const lastPage = firstPage.meta.page.last
    const promises = []
    for (let i = 2; i <= lastPage; i++) {
      promises.push(StatsEndpoint.getUserRegistrations({
        'filter[date.from]': date?.value?.from,
        'filter[date.to]': date?.value?.to,
        'filter[event]': event?.value,
        'filter[segment]': segment?.value,
        'interval': interval?.value,
        'page[number]': i,
      }))
    }
    const allData = await Promise.all(promises)
    const data = allData.reduce((acc, cur) => {
      acc.push(...cur.data.data)
      return acc
    }, [...firstPage.data])
    const total = firstPage.meta.total

    return {
      data,
      total,
    }
  }

  return useQuery({
    enabled: _enabled,
    gcTime: queryCache.analytics.default,
    queryFn: getRegistrations,
    queryKey: computed(() => queryKeys.stats.users.registrations(options)),
    refetchOnWindowFocus: false,
    staleTime: queryCache.analytics.default,
  })
}

export interface QueryUsersStatusOptions {
  date?: Ref<DateRange | undefined>
  enabled?: Ref<boolean | undefined>
  event?: Ref<string | undefined>
  segment?: Ref<string | undefined>
  user?: Ref<string | undefined>
}

export function queryUsersStatus(options: QueryUsersStatusOptions = {}) {
  const { date, enabled, event, segment, user } = options

  const _enabled = computed(() => {
    if (enabled)
      return enabled.value

    return true
  })

  async function getUsersStatus() {
    const { data } = await RostersEndpoint.getPlayerStats({
      'filter[date.from]': date?.value?.from ?? null,
      'filter[date.to]': date?.value?.to ?? null,
      'filter[event]': event?.value ?? null,
      'filter[segment]': segment?.value ?? null,
      'filter[user]': user?.value ?? null,
    })
    if (!data?.data)
      return null
    return data.data
  }

  return useQuery({
    enabled: _enabled,
    gcTime: queryCache.analytics.default,
    queryFn: getUsersStatus,
    queryKey: computed(() => queryKeys.stats.users.status(options)),
    refetchOnWindowFocus: false,
    staleTime: queryCache.analytics.default,
  })
}

export interface QueryMatchSeriesStateStatsOptions {
  date?: Ref<DateRange | undefined>
  event?: Ref<string | undefined>
  segment?: Ref<string | undefined>
  roster?: Ref<string | undefined>
  user?: Ref<string | undefined>
  enabled?: Ref<boolean | undefined>
}

export function queryMatchSeriesStateStats(options: QueryMatchSeriesStateStatsOptions = {}) {
  const { date, enabled, event, roster, segment, user } = options

  const _enabled = computed(() => {
    if (enabled)
      return enabled.value

    return true
  })

  async function getStates() {
    const { data } = await MatchesEndpoint.getMatchSeriesStateStats({
      'filter[date.from]': date?.value?.from ?? null,
      'filter[date.to]': date?.value?.to ?? null,
      'filter[event]': event?.value ?? null,
      'filter[roster]': roster?.value ?? null,
      'filter[segment]': segment?.value ?? null,
      'filter[user]': user?.value ?? null,
    })

    if (!data?.data)
      return null

    return data.data
  }

  return useQuery({
    enabled: _enabled,
    gcTime: queryCache.analytics.default,
    queryFn: getStates,
    queryKey: computed(() => queryKeys.stats.matchSeries.states(options)),
    refetchOnWindowFocus: false,
    staleTime: queryCache.analytics.default,
  })
}

// Rosters
export interface QueryRostersStatusOptions {
  date?: Ref<DateRange | undefined>
  enabled?: Ref<boolean | undefined>
  event?: Ref<string | undefined>
  segment?: Ref<string | undefined>
  user?: Ref<string | undefined>
}

export function queryRostersStatus(options: QueryRostersStatusOptions = {}) {
  const { date, enabled, event, segment, user } = options

  const _enabled = computed(() => {
    if (enabled)
      return enabled.value

    return true
  })

  async function getRostersStatus() {
    const { data } = await RostersEndpoint.getRosterStatus({
      'filter[date.from]': date?.value?.from ?? null,
      'filter[date.to]': date?.value?.to ?? null,
      'filter[event]': event?.value ?? null,
      'filter[segment]': segment?.value ?? null,
      'filter[user]': user?.value ?? null,
    })
    if (!data?.data)
      return null
    return data.data
  }

  return useQuery({
    enabled: _enabled,
    gcTime: queryCache.analytics.default,
    queryFn: getRostersStatus,
    queryKey: computed(() => queryKeys.stats.rosters.status(options)),
    refetchOnWindowFocus: false,
    staleTime: queryCache.analytics.default,
  })
}

// Match Series Chart
export interface QueryMatchSeriesChartOptions {
  date?: Ref<DateRange | undefined>
  event?: Ref<string | undefined>
  segment?: Ref<string | undefined>
  roster?: Ref<string | undefined>
  user?: Ref<string | undefined>
  enabled?: Ref<boolean | undefined>
  interval?: Ref<FilterInterval | undefined>
}

export function queryMatchSeriesChart(options: QueryMatchSeriesChartOptions = {}) {
  const { date, enabled, event, interval, roster, segment, user } = options

  const _enabled = computed(() => {
    if (date) {
      if (date.value?.from && date.value?.to)
        return enabled ? enabled.value : true
      else return !date.value
    }
    else { return enabled ? enabled.value : true }
  })

  async function getMatchSeriesChart(): Promise<{ data: MatchSeriesChartResponse[], total: GetMatchSeriesChartResponse['meta']['total'] } | null> {
    const { data: firstPage } = await StatsEndpoint.getMatchSeriesChart({
      'filter[date.from]': date?.value?.from ?? null,
      'filter[date.to]': date?.value?.to ?? null,
      'filter[event.id]': event?.value,
      'filter[roster.id]': roster?.value,
      'filter[segment.id]': segment?.value,
      'filter[user.id]': user?.value,
      'interval': interval?.value,
    })

    if (!firstPage?.data)
      return null

    const lastPage = firstPage.meta.page.last
    const promises = []
    for (let i = 2; i <= lastPage; i++) {
      promises.push(StatsEndpoint.getMatchSeriesChart({
        'filter[date.from]': date?.value?.from ?? null,
        'filter[date.to]': date?.value?.to ?? null,
        'filter[event.id]': event?.value,
        'filter[roster.id]': roster?.value,
        'filter[segment.id]': segment?.value,
        'filter[user.id]': user?.value,
        'interval': interval?.value,
        'page[number]': i,
      }))
    }
    const allData = await Promise.all(promises)
    const data = allData.reduce((acc, cur) => {
      acc.push(...cur.data.data)
      return acc
    }, [...firstPage.data])
    const total = firstPage.meta.total

    return {
      data,
      total,
    }
  }

  return useQuery({
    enabled: _enabled,
    gcTime: queryCache.analytics.default,
    queryFn: getMatchSeriesChart,
    queryKey: computed(() => queryKeys.stats.matchSeries.chart(options)),
    refetchOnWindowFocus: false,
    staleTime: queryCache.analytics.default,
  })
}

// Rosters Chart
export interface QueryRosterChartOptions {
  date?: Ref<DateRange | undefined>
  event?: Ref<string | undefined>
  segment?: Ref<string | undefined>
  user?: Ref<string | undefined>
  enabled?: Ref<boolean | undefined>
  interval?: Ref<FilterInterval | undefined>
}
export function queryRosterChart(options: QueryRosterChartOptions = {}) {
  const { date, enabled, event, interval, segment, user } = options

  const _enabled = computed(() => {
    if (date) {
      if (date.value?.from && date.value?.to)
        return enabled ? enabled.value : true
      else return !date.value
    }
    else { return enabled ? enabled.value : true }
  })

  async function get() {
    const { data: firstPage } = await StatsEndpoint.getRostersChart({
      'filter[date.from]': date?.value?.from ?? null,
      'filter[date.to]': date?.value?.to ?? null,
      'filter[event]': event?.value,
      'filter[segment]': segment?.value,
      'filter[user]': user?.value,
      'interval': interval?.value,
    })

    if (!firstPage?.data)
      return null

    const lastPage = firstPage.meta.page.last
    const promises = []
    for (let i = 2; i <= lastPage; i++) {
      promises.push(StatsEndpoint.getRostersChart({
        'filter[date.from]': date?.value?.from ?? null,
        'filter[date.to]': date?.value?.to ?? null,
        'filter[event]': event?.value,
        'filter[segment]': segment?.value,
        'filter[user]': user?.value,
        'interval': interval?.value,
        'page[number]': i,
      }))
    }
    const allData = await Promise.all(promises)
    const data = allData.reduce((acc, cur) => {
      acc.push(...cur.data.data)
      return acc
    }, [...firstPage.data])
    const total = firstPage.meta.total

    return {
      data,
      total,
    }
  }

  return useQuery({
    enabled: _enabled,
    gcTime: queryCache.analytics.default,
    queryFn: get,
    queryKey: computed(() => queryKeys.stats.rosters.chart(options)),
    refetchOnWindowFocus: false,
    staleTime: queryCache.analytics.default,
  })
}
