import { WebhooksEndpoint } from '@cevo/gfinity-api-sdk'
import type { NotificationLog, WebhookNotificationLog } from '@cevo/gfinity-api-sdk'
import { useSessionStorage } from '@vueuse/core'
import dayjs from 'dayjs'

interface Filters {
  webhookId?: string | undefined
  eventType?: string | undefined
  userId?: string | undefined
  date: {
    from?: number | undefined
    to?: number | undefined
  }
}

const currentLog = ref<NotificationLog>()

export const useCurrentWebhookLog = () => currentLog

export function useLoadWebhookLogs(initialFilters?: Filters) {
  const route = useRoute()

  const defaultFilters: Filters = {
    date: {
      from: undefined,
      to: undefined,
    },
    eventType: undefined,
    userId: undefined,
    webhookId: undefined,
  }

  const loading = ref(false)
  const page = ref(1)
  const pageCount = ref(1)
  const itemCount = ref(0)
  const logs = ref<WebhookNotificationLog[]>([])

  const config = useSessionStorage(`logs-table@${route.path}`, {
    filters: { ...defaultFilters, ...initialFilters },
    pageSize: 20,
  })

  const { filters, pageSize } = toRefs(config.value)

  const hasAppliedFilters = computed(() => Object.values(filters.value).some((v) => {
    if (typeof v === 'object' && v !== null)
      return Object.values(v).some(Boolean)

    return Boolean(v)
  }))

  const resetFilters = () => {
    Object.assign(filters.value, defaultFilters, initialFilters)
  }

  async function loadLogs(loadingFilters?: Filters) {
    if (loadingFilters)
      filters.value = loadingFilters

    const { date: { from, to }, eventType, userId, webhookId } = filters.value
    loading.value = true

    try {
      const resp = await WebhooksEndpoint.getLogs({
        'page[number]': page.value,
        'page[size]': pageSize.value,
        ...(webhookId ? { 'filter[webhookId]': webhookId } : null),
        ...(eventType ? { 'filter[eventType]': eventType } : null),
        ...(userId ? { 'filter[userId]': userId } : null),
        ...(from ? { 'filter[date.from]': dayjs(from).format() } : null),
        ...(to ? { 'filter[date.to]': dayjs(to).format() } : null),
      })

      logs.value = resp.data.data
      itemCount.value = resp.data.meta?.page?.total || 0
      pageCount.value = resp.data.meta.page.total > 0 ? Math.ceil(resp.data.meta.page.total / resp.data.meta.page.size) : 1
    }
    catch (err) {
      console.error(err)
    }
    finally {
      loading.value = false
    }
  }

  const onUpdatePage = (p: number) => {
    page.value = p
    loadLogs()
  }

  const onUpdatePageSize = (ps: number) => {
    page.value = 1
    pageSize.value = ps
    config.value.pageSize = ps
    loadLogs()
  }

  const onUpdateFilters = (f: Filters) => {
    filters.value = f
    page.value = 1
    config.value.filters = f
    loadLogs()
  }

  return {
    filters,
    hasAppliedFilters,
    itemCount,
    loading,
    loadLogs,
    logs,
    onUpdateFilters,
    onUpdatePage,
    onUpdatePageSize,
    page,
    pageCount,
    pageSize,
    resetFilters,
  }
}
