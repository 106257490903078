import { zones } from 'tzdata'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'

dayjs.extend(utc)
dayjs.extend(timezone)

const timezones = Object.entries(zones)
  .filter(([_zoneName, v]) => Array.isArray(v))
  .map(([zoneName, _v]) => zoneName)
  .filter((tz) => {
    try {
      return dayjs().tz(tz)
    }
    catch (error) {
      return false
    }
  })

timezones.sort()

export { timezones }

export default timezones
