import type { Competitor, MatchSeries } from '@cevo/gfinity-api-sdk'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export function getHomeTeam(match: MatchSeries | undefined | null) {
  return match?.competitors?.[0]
}

export function getAwayTeam(match: MatchSeries | undefined | null) {
  return match?.competitors?.[1]
}

export function getTeamUser(competitor: Competitor | undefined | null) {
  return competitor?.roster?.players?.[0]?.user
}

export function competitorIsBye(competitor: Competitor | undefined) {
  if (!competitor)
    return false

  return competitor.isBye
}

export function isHomeForfeitWin(
  homeTeam: Competitor | undefined,
  awayTeam: Competitor | undefined,
) {
  if (!homeTeam || !awayTeam)
    return false

  return (
    homeTeam?.result?.forfeit === false && awayTeam?.result?.forfeit === true
  )
}

export function isAwayForfeitWin(
  homeTeam: Competitor | undefined,
  awayTeam: Competitor | undefined,
) {
  if (!homeTeam || !awayTeam)
    return false

  return (
    homeTeam?.result?.forfeit === true && awayTeam?.result?.forfeit === false
  )
}

export function isForfeited(homeTeam: Competitor | undefined, awayTeam: Competitor | undefined) {
  return isHomeForfeitWin(homeTeam, awayTeam) || isAwayForfeitWin(homeTeam, awayTeam)
}

export function isDoubleDq(
  matchSeries: MatchSeries | undefined,
) {
  if (!matchSeries)
    return false

  return !!matchSeries.isDoubleForfeit
}

export function getHomeTeamScore(
  homeTeam: Competitor | undefined,
  homeFFW: boolean | undefined | null,
  awayFFW: boolean | undefined | null,
  doubleDq: boolean | undefined | null,
) {
  if (!homeTeam)
    return '-'

  if (doubleDq)
    return 'FFL'

  if (homeFFW)
    return 'FFW'

  if (awayFFW)
    return 'FFL'

  return homeTeam?.result?.score ?? '-'
}

export function getAwayTeamScore(
  awayTeam: Competitor | undefined,
  homeFFW: boolean,
  awayFFW: boolean,
  doubleDq: boolean | undefined | null,
) {
  if (!awayTeam)
    return '-'

  if (doubleDq)
    return 'FFL'

  if (awayFFW)
    return 'FFW'

  if (homeFFW)
    return 'FFL'

  return awayTeam?.result?.score ?? '-'
}

export function getMatchStatus(
  homeBye: boolean | undefined | null,
  awayBye: boolean | undefined | null,
  match: MatchSeries,
  homeFFW: boolean,
  awayFFW: boolean,
  doubleDq: boolean,
) {
  if (homeBye || awayBye)
    return 'BYE'

  else if (!match)
    return 'TBD'

  else if (match?.state !== 'finished')
    return 'TBD'

  else if (homeFFW || awayFFW || doubleDq)
    return 'Final'

  else if (match?.state === 'finished')
    return 'Final'

  return ''
}

export function getTeamWon(team: Competitor | undefined) {
  return team?.placement === 1
}

export function getMatchTime(match: MatchSeries) {
  if (!match || !match.date)
    return 'TBD'

  return dayjs(match.date).format('HH:mm z')
}

export function getMatchDate(match: MatchSeries) {
  if (!match || !match.date)
    return 'TBD'
  return dayjs(match.date).format('DD MMM')
}

export function getMatchDateFull(match: MatchSeries) {
  if (!match || !match.date)
    return 'TBD'

  return dayjs(match.date).format('DD MMMM YYYY')
}

export function getTeamName(team: Competitor | undefined) {
  if (competitorIsBye(team))
    return 'BYE'

  return team?.roster?.name || team?.roster?.players?.[0]?.user || 'TBD'
}
