<script setup lang="ts">
const props = withDefaults(defineProps<{
  tag?: 'div' | 'span' | 'form'
  square?: boolean
  outlined?: boolean
}>(), {
  tag: 'div',
})

const classes = computed(() => ({
  'b-card': true,
  'b-card__outlined': props.outlined,
  'b-card--square': props.square,
}))
</script>

<template>
  <component :is="tag" :class="classes">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
.b-card {
  @apply b-rd-2 vertical-top bg-white relative shadow;

  > div, > img {
    &:first-child {
      @apply b-t-0 b-rd-tl-inherit b-rd-tr-inherit;
    }
    &:last-child {
      @apply b-b-0 b-rd-bl-inherit b-rd-br-inherit;
    }
    &:not(:first-child) {
      @apply b-rd-tl-0 b-rd-tr-0;
    }
    &:not(:last-child) {
      @apply b-rd-bl-0 b-rd-br-0;
    }
  }

  > div {
    @apply b-l-0 b-r-0;
  }

  > img {
    @apply block w-full max-w-full b-0;
  }

  &--square {
    @apply important-b-rd-0;
  }

  &__outlined {
    @apply b-1 important-b-dark-5 shadow-none;
  }
}
</style>
