import type { VNode } from 'vue'
import type { ModalInjection } from '~/components/base/b-modal.types'
import { ModalInjectionKey } from '~/components/base/b-modal.types'

let modal: ModalInjection | undefined

export const vCloseModal = {
  beforeMount(el: HTMLElement, bind: any, vnode: VNode) {
    // @ts-expect-error Vue don't want us to access ctx =D but we need it
    modal = vnode.ctx.provides[ModalInjectionKey]
    el.addEventListener('click', () => modal?.onClose())
  },
  onBeforeUnmount(el: HTMLElement) {
    el.removeEventListener('click', () => modal?.onClose())
  },
}
