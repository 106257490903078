<script setup lang="ts">
import type { BDropdownOption } from '~/components/base/b-dropdown.types'
import { useAppStore } from '~/store'
import { useUserStore } from '~/store/users'

const { t } = useI18n()

const router = useRouter()
const appStore = useAppStore()
const userStore = useUserStore()
const userOptions: BDropdownOption[] = [
  {
    key: 'change-password',
    label: t('auth.changePassword'),
    props: {
      onClick: async () => {
        router.push('/password/reset')
      },
    },
  },
  {
    key: 'logout',
    label: t('common.logout'),
    props: {
      onClick: async () => {
        await userStore.logout()
        router.push('/login')
      },
    },
  },
]

const showTenantList = ref(false)
const showUserAction = ref(false)

const tenant = computed(() => appStore.tenant)
const user = computed(() => userStore.loggedUser)
const tenantOptions = computed<BDropdownOption[]>(() => userStore.tenants.map(t => ({ key: t, label: t })))
const isDrawerShown = computed(() => appStore.isDrawerShown)

function onClose() {
  showTenantList.value = false
  showUserAction.value = false
}
</script>

<template>
  <b-navdrawer :show="isDrawerShown" @close="onClose">
    <div class="mb-6">
      <div v-if="tenantOptions.length === 1" class="flex cursor-pointer items-center justify-between rounded-2 bg-dark-5 px-4 py-2 page-heading">
        {{ appStore.tenant ?? '???' }}
      </div>

      <div v-else>
        <div class="flex cursor-pointer items-center justify-between rounded-2 bg-dark-5 px-4 py-2 page-heading" @click="showTenantList = !showTenantList">
          <div>
            {{ appStore.tenant ?? '???' }}
          </div>
          <div class="i-tabler-chevron-down" />
        </div>
        <b-collapse>
          <div v-show="showTenantList" class="mt-2 w-full">
            <ul>
              <li
                v-for="row in tenantOptions"
                :key="row.label"
                class="flex cursor-pointer items-center gap-2 b-rd-1 p-2 hover:bg-dark-5"
                :class="{
                  'important-bg-dark-10': row.key === tenant,
                }"
                @click="appStore.onChangeTenant(row.key)"
              >
                {{ row.label }}
              </li>
            </ul>
          </div>
        </b-collapse>
      </div>
    </div>

    <b-divider class="mb-6" />

    <div class="flex flex-col">
      <div class="flex cursor-pointer items-center gap-1" @click="showUserAction = !showUserAction">
        <div class="content-heading">
          {{ user?.username ?? '???' }}
        </div>
        <div class="i-tabler-chevron-down" />
      </div>
      <b-collapse>
        <div v-show="showUserAction" class="mt-2 w-full">
          <ul>
            <li
              v-for="row in userOptions"
              :key="row.label"
              class="flex cursor-pointer items-center gap-2 p-2"
              @click="row.props?.onClick?.()"
            >
              {{ row.label }}
            </li>
          </ul>
        </div>
      </b-collapse>
    </div>
  </b-navdrawer>
</template>
