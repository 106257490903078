import type { AxiosError } from 'axios'
import axios from 'axios'
import * as Sentry from '@sentry/vue'
import { Message } from './message'

interface AthlosError {
  errors: {
    code: number
    debug: any
    detail: string
    status: number
    title: string
  }[]
}

export function handleExpected404(
  e: AxiosError<AthlosError> | any,
  fallbackMessage?: string,
) {
  if (!(axios.isAxiosError(e) && e.response?.status === 404))
    handleError(e, fallbackMessage)
}

export function handleError(
  e: AxiosError<AthlosError> | any,
  fallbackMessage?: string,
) {
  console.error(e)
  Sentry.captureException(e)
  function hasAxiosError(e: any): e is AxiosError<AthlosError> {
    return !!e.response
  }
  if (hasAxiosError(e) && e.response?.data?.errors?.length) {
    e.response?.data?.errors.forEach((error) => {
      if (error.detail)
        Message.error(error.detail)
    })
  }
  else if (e.message) {
    Message.error(e.message)
  }
  else if (typeof e === 'string') {
    Message.error(e)
  }
  else if (fallbackMessage) {
    Message.error(fallbackMessage)
  }
}
