<script lang="ts" setup>
import { ChevronDown, Clipboard } from '@vicons/tabler'
import { EventsEndpoint, MatchesEndpoint, RostersEndpoint, UsersEndpoint } from '@cevo/gfinity-api-sdk'
import { handleError } from '~/plugins'
import { isMenuActive, menuOptions } from '~/utils'
import { useAppStore } from '~/store'
import { useUserStore } from '~/store/users'
import logo from '~/assets/logo.svg'
import type { BDropdownOption } from '~/components/base/b-dropdown.types'
import type { MenuItem } from '~/utils'

const appStore = useAppStore()
const userStore = useUserStore()
const router = useRouter()
const tenantRouter = useTenantRouter()
const { t } = useI18n()
const route = useRoute()

const tenant = computed(() => appStore.tenant)
const tenantOptions = computed<BDropdownOption[]>(() => userStore.tenants.map(t => ({ key: t, label: t })))
const options = computed<MenuItem[]>(() => tenant.value ? menuOptions() : [])
const user = computed(() => userStore.loggedUser)

const userOptions: BDropdownOption[] = [
  {
    key: 'change-password',
    label: t('auth.changePassword'),
    props: {
      onClick: async () => {
        router.push('/password/reset')
      },
    },
  },
  {
    key: 'logout',
    label: t('common.logout'),
    props: {
      onClick: async () => {
        await userStore.logout()
        router.push('/login')
      },
    },
  },
]

const searchString = ref<string>()
async function searchById() {
  try {
    // a User matching that ID,
    // a Roster matching that ID,
    // a MatchSeries matching that ID,
    // an Event matching that id
    // xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx (8 chars a-z + 0-9)-(4 chars a-z + 0-9)-(4 chars a-z + 0-9)-(4 chars a-z + 0-9)-(12 chars a-z + 0-9)
    if (!searchString.value)
      return
    if (
      /^([a-z0-9]{8})-([a-z0-9]{4})\-([a-z0-9]{4})\-([a-z0-9]{4})\-([a-z0-9]{12})$/.test(
        searchString.value,
      )
    ) {
      const [eventResp, matchSeriesResp, usersResp, rosterResp, segmentsResp]
        = await Promise.allSettled([
          EventsEndpoint.getEventById(searchString.value),
          MatchesEndpoint.getMatchSeriesById(searchString.value),
          UsersEndpoint.getUser(searchString.value),
          RostersEndpoint.getRosterDetail(searchString.value),
          EventsEndpoint.getSegmentById(searchString.value),
        ])
      const index = [
        eventResp,
        matchSeriesResp,
        usersResp,
        rosterResp,
        segmentsResp,
      ].findIndex((request: any) => request.status === 'fulfilled')
      const types = ['events', 'matches', 'users', 'rosters', 'segments']

      if (index !== -1) {
        const validResp: any = [
          eventResp,
          matchSeriesResp,
          usersResp,
          rosterResp,
          segmentsResp,
        ].find((request: any) => request.status === 'fulfilled')
        let jumpToResult = validResp?.value?.data
        const type = types[index]
        let redirectRoute
        if (type === 'segments') {
          redirectRoute = `/events/${jumpToResult?.data?.event?.id}/segment/${jumpToResult?.data?.id}`
        }
        else if (type === 'rosters') {
          redirectRoute = `/participants/${jumpToResult?.data?.id}`
        }
        else {
          jumpToResult = jumpToResult?.data?.id ? jumpToResult?.data : jumpToResult
          redirectRoute = `/${type || ''}/${jumpToResult.id}`
        }

        searchString.value = undefined
        tenantRouter.push(redirectRoute)
      }
      else {
        throw new Error(t('error.404'))
      }
    }
    else {
      throw new Error(t('jumpTo.invalidId'))
    }
  }
  catch (err: any) {
    handleError(err)
  }
}

// hide param is a callback to close dropdown after click
function onRoute(item: MenuItem, hide?: () => void) {
  if (!item.path || item.disabled)
    return

  if (item.key === 'index') {
    router.push({ name: item.key })
    hide?.()
    return
  }

  tenantRouter.push(item.path)
  hide?.()
}

const activeRoute = computed(() => options.value?.reduce(
  (acc: MenuItem | undefined, item) => {
    if (isMenuActive(item, route.name))
      return item
    if (item.children) {
      const child = item.children.find(subitem => isMenuActive(subitem, route.name, item))
      if (child)
        return child
    }
    return acc
  },
  undefined,
),
)

function pasteText() {
  navigator.clipboard.readText().then((text) => {
    searchString.value = text
    searchById()
  })
}

function onOpenDrawer() {
  appStore.setDrawerShown(true)
}
</script>

<template>
  <nav class="w-full b-b-1 b-toolbar b-solid">
    <!-- TOP BAR -->
    <div class="h-[calc(68rem/16)] w-full flex items-center justify-between bg-toolbar px-5 text-toolbar-text">
      <div class="flex items-center">
        <img height="25" :src="logo" alt="Logo Symbol">
      </div>
      <div class="block ta:hidden">
        <div class="i-tabler-menu2 text-3xl" @click="onOpenDrawer" />
      </div>
      <div class="hidden ta:block">
        <b-dropdown
          placement="bottom-start"
          trigger="click"
          size="small"
          :options="userOptions"
        >
          <div data-testid="nav-user-profile" class="flex cursor-pointer items-center gap-1">
            <div class="content-heading">
              {{ user?.username ?? '???' }}
            </div>
            <div class="i-tabler-chevron-down" />
          </div>
        </b-dropdown>
      </div>
    </div>
    <!-- MOBILE LOW BAR -->
    <div class="h-[calc(68rem/16)] w-full flex items-center justify-between bg-white px-5 text-black">
      <div class="hidden ta:flex">
        <div v-if="tenantOptions.length === 1" class="text-[calc(26rem/16)] font-500 lh-[calc(36.rem/16)]">
          {{ appStore.tenant ?? '???' }}
        </div>
        <b-dropdown
          v-else
          :options="tenantOptions"
          :selected="tenant"
          placement="bottom-end"
          min-width="12rem"
          @select="appStore.onChangeTenant"
        >
          <div class="flex cursor-pointer items-center gap-2">
            <div class="text-[calc(26rem/16)] font-500 lh-[calc(36rem/16)]">
              {{ appStore.tenant ?? '???' }}
            </div>
            <b-icon size="21px">
              <chevron-down />
            </b-icon>
          </div>
        </b-dropdown>
      </div>
      <div class="block ta:hidden">
        <b-dropdown>
          <div class="h-full w-full flex flex cursor-pointer items-center justify-between gap-2">
            <div class="text-[calc(26rem/16)] font-500 lh-[calc(36rem/16)]">
              {{ activeRoute?.label }}
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 32 32"><path fill="black" d="m16 28l-7-7l1.41-1.41L16 25.17l5.59-5.58L23 21l-7 7zm0-24l7 7l-1.41 1.41L16 6.83l-5.59 5.58L9 11l7-7z" /></svg>
            </div>
          </div>

          <template #content="{ hide }">
            <template v-for="item, index in options" :key="item.key">
              <div
                :class="{
                  'b-b-1 b-solid b-dark-5 my-3': index !== 0,
                  'b-b-4 mb-2': !!item.children?.length,
                }"
              />
              <div
                v-if="item.show === false ? false : true"
                class="lh-140%"
                :class="{
                  'mt-1': index === 0,
                  'mb-1': index === options.length - 1,
                  'drawer-menu__header !pl-0 mt-2': !!item.children?.length,
                  'drawer-menu__item': !item.children?.length,
                  'drawer-menu__item__actionable': !!item.path,
                  'drawer-menu__item__disabled': !!item.disabled,
                  'drawer-menu__item__active': isMenuActive(item, route?.name),
                }"
                @click="onRoute(item, hide)"
              >
                <div
                  :class="{
                    'drawer-menu__header__label !pl-2 pb-2 mb-3 b-b-1 b-solid b-dark-5': item.children?.length,
                  }"
                >
                  {{ item.children?.length ? item.label.toUpperCase() : item.label }}
                </div>
                <template
                  v-for="subitem, subIndex in item.children"
                  :key="subitem.key"
                >
                  <div :class="{ 'b-b-1 b-solid b-dark-5 my-3': subIndex !== 0 }" />
                  <div
                    class="drawer-menu__item"
                    :class="{
                      'mt-1': subIndex === 0,
                      'mt-2': subIndex !== 0,
                      'drawer-menu__item__actionable cursor-pointer': !!subitem.path,
                      'drawer-menu__item__disabled': subitem.disabled,
                      'drawer-menu__item__active': isMenuActive(subitem, route?.name, item),
                    }"
                    @click="onRoute(subitem, hide)"
                  >
                    {{ subitem.label }}
                  </div>
                </template>
              </div>
            </template>
          </template>
        </b-dropdown>
      </div>
      <div class="hidden w-75 ta:block">
        <b-input
          v-model="searchString"
          type="text"
          class="h-9"
          :placeholder="$t('common.jumpTo')"
          clearable
          @keyup.enter="searchById"
        >
          <template #suffix>
            <transition name="fade">
              <b-icon
                v-if="!searchString || searchString.length === 0"
                size="16px"
                class="cursor-pointer !color-dark-20 !hover:color-dark-40"
                @click="pasteText"
              >
                <clipboard />
              </b-icon>
            </transition>
          </template>
        </b-input>
      </div>
      <div class="hidden ta:block" />
    </div>
  </nav>
</template>
